<template>
  <div class="SilentExposure">
    <div class="data-show">
      <div class="data-count-container">
        <div class="exposure-count-block">
          <div class="exposure-count-container">
            <div class="exposure-count-title"><i class="iconfont unify-a-sunrise-arrow-up1"></i>馆藏沉寂总值</div>
            <div class="exposure-count-text">{{state.resourceStatistics.stillValue}}</div>
          </div>

        </div>
        <div class="exposure-display">
          <div class="exposure-display-title"><i class="iconfont unify-a-pie-chart-2graphchartstatistics"></i>沉寂资源分布</div>
          <div class="exposure-display-container">
            <div class="exposure-display-block">
              <div class="exposure-display-count">{{state.resourceStatistics.enJournalNum || 0}}</div>
              <div class="exposure-display-name">外文期刊沉寂</div>
            </div>
            <div class="exposure-display-block">
              <div class="exposure-display-count">{{state.resourceStatistics.cnJournalNum || 0}}</div>
              <div class="exposure-display-name">中文期刊沉寂</div>
            </div>
            <div class="exposure-display-block">
              <div class="exposure-display-count">{{state.resourceStatistics.enBookNum || 0}}</div>
              <div class="exposure-display-name">外文图书沉寂</div>
            </div>
            <div class="exposure-display-block">
              <div class="exposure-display-count">{{state.resourceStatistics.cnBookNum || 0}}</div>
              <div class="exposure-display-name">中文图书沉寂</div>
            </div>
          </div>
        </div>
      </div>
      <div class="exposure-echarts">
        <div class="exposure-echarts-title">
          <i class="iconfont unify-a-chart-5statisticsgraph"></i>
          近30日资源曝光情况
        </div>

        <commonEcharts propId="echarts-type" ref="charts1" echarts-height="350px"/>
      </div>
    </div>
    <div class="table-container">
        <div class="table-title flex a-c">
          <div class="title-text">
            <i class="iconfont unify-a-calendar-tear-offdate"></i>
            资源曝光明细
          </div>
          <div class="title-color">曝光沉寂值：{{state.exposureRecordStillValue}}</div>

          <div class="title-button" @click="promoteFunction" v-loading="exportLoading"
          ><i class="iconfont unify-a-interactive-buttonshare-external"></i>导出曝光记录</div>
        </div>
      <div class="table-title flex a-c">
        <div class="input-container flex a-c">
          <div class="time-select-button" @click="getTadoy">今日</div>
          <div class="time-select-button" @click="getWeekDay">近七日</div>
          <el-date-picker
              v-model="value2"
              @change="getData"
              type="daterange"
              value-format="YYYY-MM-DD"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div style="margin-left: auto">
          <el-select v-model="paramsFilter.stillFlag" placeholder="全部资源"
                     @change="getData"
                     style="width: 160px">
            <el-option  label="全部资源" value="" />
            <el-option  label="沉寂资源" value="1" />
          </el-select>
        </div>
        <div style="margin-left: 30px">
          <el-select v-model="paramsFilter.datatype" placeholder="全部类型"
                     @change="getData"
                     style="width: 160px">
            <el-option  label="全部类型" value="" />
            <el-option  label="外文期刊" value="1" />
            <el-option  label="中文期刊" value="3" />
            <el-option  label="外文图书" value="2" />
            <el-option  label="中文图书" value="4" />
          </el-select>
        </div>
      </div>
      <el-table :data="state.tableData"
                stripe style="width: 100%" class="my-table" v-loading="tableLoading">
        <el-table-column label="序号" align="center" width="100px">
          <template #default="scope">
            <div class="overtext">
              {{scope.$index + 1}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="resourceName" label="资源名称" align="left" >
          <template #default="scope">
            <div class="overtext">
              {{scope.row.resourceName || '-'}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="datatypeStr" label="类型" align="center" width="180px">
          <template #default="scope">
            {{scope.row.datatypeStr || '-'}}
          </template>
        </el-table-column>
        <el-table-column prop="stillValue" label="沉寂值" align="center" width="200px">
          <template #default="scope">
            {{ scope.row.stillValue || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="exposureNum" label="曝光次数" align="center" width="180">
          <template #default="scope">
            {{ scope.row.exposureNum || '-' }}
          </template>
        </el-table-column>

      </el-table>
      <div class="flex a-c" style="justify-content: flex-end;margin-top: 20px">
        <span style="margin-right: 12px;color: #6573A2;font-size: 16px">总数：{{ total }}</span>
        <div class="page-button-pre" @click="handlePageClick(false)">上一页</div>
        <el-pagination
            background
            layout="pager,sizes"
            v-model:page-size="paramsFilter.pageSize"
            :page-sizes="[5,10,20]"
            :current-page="paramsFilter.pageNum"
            @current-change="handlerPageNumChange"
            @size-change="handlerPageSizeChange"
            :total="total"/>
        <div class="page-button-next" @click="handlePageClick(true)">下一页</div>
      </div>
    </div>
  </div>
</template>

<script>
import commonEcharts from "../../MonitoringPanel/CounterSystem/DatabaseNav/commonEcharts"
import {onMounted, reactive, ref} from "vue";
import {
  findStillResourceChartStatistics,
  findExposureRecordModelListPage,
  findTodayExposureRecordStillValue,
  findStillResourceStatistics, exportPost
} from "@/js/network/main";
import {MessageBox} from "@/js/utils/commonJs";
import {download} from "@/js/utils/download";
export default {
  components: {
    commonEcharts
  },
  setup(props, {attrs,slots,emit,expose}) {
    const charts1 = ref(null);
    const value2 = ref('');
    let state = reactive({
      tableData: [],
      exposureRecordStillValue:0,
      resourceStatistics:{},
      filterListObj: {},// 表头筛选枚举
    })
    let paramsFilter = reactive({ // 筛选参数
      pageSize: 10,//当前页大小
      pageNum: 1,//当前页
      stillFlag:"",//1是，0否
      startTime:"",
      endTime:"",
      datatype:"",
    });
    let total = ref(0);
    let tableLoading = ref(true);
    let stillLoading = ref(true);
    let statisticsLoading = ref(true);
    let exportLoading = ref(false);
    const getTadoy = function(){
      const today = new Date();
      // 获取最近一周的结束时间（今天）
      const endDate = today;
      // 格式化为 'YYYY-MM-DD'
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
      value2.value = [formatDate(endDate),formatDate(endDate)];
      getData();
    }
    const getWeekDay = function(){
      const today = new Date();

      // 获取最近一周的结束时间（今天）
      const endDate = today;

      // 获取最近一周的开始时间（7天前）
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - 6);  // 从今天算起的第六天是最近一周的开始时间
      // 格式化为 'YYYY-MM-DD'
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
      value2.value = [formatDate(startDate),formatDate(endDate)];
      getData();

    }
    const initChart = function (){
      charts1.value.loading();
      findStillResourceChartStatistics().then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          if(charts1.value){
            charts1.value.hideLoading();
          }
          return;
        }
        if (!res || !res.data) {
          if(charts1.value){
            charts1.value.hideLoading();
          }
          return;
        }
        let options = initBarOptions(res.data);

        if(charts1.value){
          charts1.value.setOption(options);
        }
      }).catch(error => {
        if(charts1.value){
          charts1.value.hideLoading();
        }
        console.log(error, "catchError");
      });
    }
    const initBarOptions = function (data) {
      let xA = [];
      let barA = [];
      let barB = [];
      let barC = [];
      let lineA = []
      data.map(r => {
        xA.push(r.dateStr);
        barA.push(r.promotionNum);
        barB.push(r.stillNum);
        barC.push(r.totalNum);
        lineA.push(r.stillValueNum);
      })
      let option = {
        // title: {
        //   text: '读者卡统计',
        //   left: 'left'
        // },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            // formatter: function (param) {
            //   console.log(param,"params")
            //   param = param[0];
            //   return [
            //     '数据库点击: ' + param.data[0] + '<br/>',
            //     '链接点击: ' + param.data[1] + '<br/>',
            //   ].join('');
            // }
          },

        },
        legend: {
          data: ['推广曝光量', '沉寂曝光量','曝光总量','曝光沉寂值']
        },
        xAxis: {
          show: true,
          type: 'category',
          data: xA,
        },
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              formatter: '{value}'
            },
            position: 'left',
          },
          {
            type: 'value',
            name: '曝光沉寂值',
            position: 'right',
          }
        ],
        grid: {
          left: "10px",
          right: "10px",
          bottom: "10px",
          top: "10%",
          containLabel: true,
        },
        series: [
          {
            name: '推广曝光量',
            type: 'bar',
            data: barA
          },
          {
            name: '沉寂曝光量',
            type: 'bar',
            data: barB
          },
          {
            name: '曝光总量',
            type: 'bar',
            data: barC
          },
          {
            name: '曝光沉寂值',
            type: 'line',
            data: lineA,
            yAxisIndex: 1,  // 使用第二个 Y 轴
          },
        ]
      };
      return option;
    }
    const getData = function(){
      paramsFilter.pageNum = 1;
      getTable();
      getExposureRecordStillValue();
    }
    //初始化操作
    const getTable = function () {
      tableLoading.value = true;
      if(value2.value && value2.value.length>0){
        paramsFilter.startTime = value2.value[0];
        paramsFilter.endTime = value2.value[1];
      }else{
        paramsFilter.startTime = "";
        paramsFilter.endTime = "";
      }
      findExposureRecordModelListPage({...paramsFilter}).then(res => {
        if (!res || !res.data) {
          tableLoading.value = false;
          return;
        }
        state.tableData = res.data;
        total.value = parseInt(res.count);
        tableLoading.value = false;
      }).catch(error => {
        if (error.message == "Request Canceled!") {
          return
        }
        tableLoading.value = false;
      });
    }
    const getExposureRecordStillValue = function () {
      stillLoading.value = true;
      if(value2.value && value2.value.length>0){
        paramsFilter.startTime = value2.value[0];
        paramsFilter.endTime = value2.value[1];
      }else{
        paramsFilter.startTime = "";
        paramsFilter.endTime = "";
      }
      findTodayExposureRecordStillValue(paramsFilter).then(res => {
        if (!res || !res.data) {
          state.exposureRecordStillValue = 0;
          stillLoading.value = false;
          return;
        }
        state.exposureRecordStillValue = res.data;
        stillLoading.value = false;
      }).catch(error => {
        if (error.message == "Request Canceled!") {
          return
        }
        stillLoading.value = false;
      });
    }
    const getStillResourceStatistics = function () {
      statisticsLoading.value = true;
      if(value2.value && value2.value.length>0){
        paramsFilter.startTime = value2.value[0];
        paramsFilter.endTime = value2.value[1];
      }else{
        paramsFilter.startTime = "";
        paramsFilter.endTime = "";
      }
      findStillResourceStatistics({...paramsFilter,pageSize:'',pageNum:""}).then(res => {
        if (!res || !res.data) {
          statisticsLoading.value = false;
          return;
        }
        state.resourceStatistics = res.data;
        statisticsLoading.value = false;
      }).catch(error => {
        if (error.message == "Request Canceled!") {
          return
        }
        statisticsLoading.value = false;
      });
    }


    const handlerPageNumChange = function (num) {
      paramsFilter.pageNum = num;
      getTable();
    }
    const handlerPageSizeChange = function (num) {
      paramsFilter.pageSize = num;
      paramsFilter.pageNum = 1;
      getTable();
    }
    const handlePageClick = function (flag) {
      flag ? paramsFilter.pageNum++ : paramsFilter.pageNum--;
      if(paramsFilter.pageNum<1){
        paramsFilter.pageNum = 1;
        return;
      }
      let max = Math.ceil(total.value/paramsFilter.pageSize);
      if(paramsFilter.pageNum>max){
        paramsFilter.pageNum = max;
        return;
      }
      getTable();
    }
    const promoteFunction = function(){
      exportLoading.value = true;
      if(value2.value && value2.value.length>0){
        paramsFilter.startTime = value2.value[0];
        paramsFilter.endTime = value2.value[1];
      }else{
        paramsFilter.startTime = "";
        paramsFilter.endTime = "";
      }
      exportPost({...paramsFilter,pageSize:'',pageNum:""}).then(res => {
        exportLoading.value = false;
        download(res,"",'excel');
      }).catch(error => {
        exportLoading.value = false;
        //MessageBox.error(error);
        console.log(error, "catchError");
      });
    }
    onMounted(()=>{
      initChart();
      getTable();
      getExposureRecordStillValue();
      getStillResourceStatistics();
    })
    return {
      value2,
      state,
      paramsFilter,
      total,
      tableLoading,
      stillLoading,
      statisticsLoading,
      exportLoading,
      charts1,
      initChart,
      initBarOptions,
      getTadoy,
      getWeekDay,
      getTable,
      getData,
      getExposureRecordStillValue,
      getStillResourceStatistics,
      handlerPageNumChange,
      handlerPageSizeChange,
      handlePageClick,
      promoteFunction

    }


  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.SilentExposure{
  display: flex;
  flex-direction: column;
}
.data-show{
  background: #FFFFFF;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
}
.data-count-container{
  width: 350px;
  margin-right: 20px;
}
.exposure-count-block{
  width: 350px;
  height: 140px;
  background: linear-gradient( 146deg, #5484FA 0%, #0B3EBF 100%);
  border-radius: 6px;
  margin-bottom: 23px;
}
.exposure-count-container{
  padding: 30px 0px 30px 30px;
  box-sizing: border-box;
  background-image: url("https://miniopreview.resourcemap.com.cn/ersmap-platform/system/unify/tiaowen.png");
  width: 100%;
  height: 100%;
  background-size: 100% auto;
}
.exposure-count-title{
  font-size: 16px;
  color: #FFFFFF;
  margin-bottom: 8px;
  .iconfont{
    font-size: 18px;
    margin-right: 5px;
  }
}
.exposure-count-text{
  font-weight: bold;
  font-size: 36px;
  color: #FFFFFF;
}
.exposure-display{

}
.exposure-display-title{
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  margin-bottom: 15px;
  .iconfont{
    color: #2054D6;
    margin-right: 5px;
    font-size: 18px;
  }
}
.exposure-display-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 6px;
}
.exposure-display-block{
  //width: 100%;
  height: 76px;
  background: #F8F9FD;
  border-radius: 10px 10px 10px 10px;
  padding: 14px 72px 14px 20px;
  box-sizing: border-box;
}
.exposure-display-count{
  font-weight: bold;
  font-size: 24px;
  color: #2054D6;
}
.exposure-display-name{
  font-size: 12px;
  color: #86909C;
}




.exposure-echarts{
  flex: 1;

}
.exposure-echarts-title{
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  .iconfont{
    color: #2054D6;
    font-size: 18px;
    margin-right: 5px;
  }
}

.table-container{
  flex: 1;
  background-color: #ffffff;
  padding: 20px;
  box-sizing: border-box;
}
.table-title{
  margin-bottom: 15px;
}
.input-container {
  border: 1px solid #dcdfe6;
  width: 500px;

  :deep(.el-range-editor) {
    height: 28px;
    box-shadow: none;
  }

  :deep(.el-range-editor:hover) {
    box-shadow: none;
  }
}

.time-select-button {
  width: 90px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  color: #a8abb2;
  font-size: 14px;
  border-right: 1px solid #dcdfe6;
  cursor: pointer;
  &.checked{
    background-color: #2054D6;
    color: #ffffff;
  }
}
.title-text{
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  .iconfont{
    color: #2054D6;
    font-size: 18px;
    margin-right: 5px;
  }
}
.title-color{
  background: #FFEFEF;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #FF0000;
  padding: 4px;
  box-sizing: border-box;
  font-size: 14px;
  color: #FF0000;
  margin-left: 15px;
}
.title-button{
  width: 146px;
  height: 36px;
  background: #2054D6;
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  line-height: 36px;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
  margin-left: auto;
}
.my-table {
  border: 1px solid #DCE1F0;
  border-radius: 10px 10px 10px 10px;
}
.page-button-next {
  width: 60px;
  height: 35px;
  background: #EEF2FE;
  border-radius: 10px 10px 10px 10px;
  font-size: 14px;
  color: #6573A2;
  line-height: 35px;
  text-align: center;
  opacity: 1;
  border: 1px solid #DCE1F0;
  user-select: none;
  cursor: pointer;
  margin-left: 15px;
}

.page-button-pre {
  width: 60px;
  height: 35px;
  background: #EEF2FE;
  border-radius: 10px 10px 10px 10px;
  font-size: 14px;
  line-height: 35px;
  text-align: center;
  color: #6573A2;
  opacity: 1;
  border: 1px solid #DCE1F0;
  user-select: none;
  cursor: pointer;
  margin-right: 15px;
}

:deep(.el-table__header) {


  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  height: 44px;

  .el-table__cell {
    background: #EEF2FE !important;
    color: #6573A2;
  }
}

:deep(.el-table__row) {
  height: 44px;

  .el-table__cell {
    color: #6573A2;
  }
}

:deep(.el-table__row--striped) {

  .el-table__cell {
    background: #F8F9FD !important;
  }
}

:deep(.el-pager) {
  .number {
    font-size: 14px;
    color: #6573A2;
    background: none !important;
  }

  .more {
    background: none !important;
  }

  .is-active {
    width: 35px;
    height: 35px;
    background: #2054D6 !important;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    border: 1px solid #DCE1F0;
  }
}
.overtext{
  overflow: hidden;
  display: -webkit-box; //使用自适应布局
  -webkit-line-clamp: 1; //设置超出行数，要设置超出几行显示省略号就把这里改成几
  -webkit-box-orient: vertical;
}
</style>
