<template>
  <div class="qps echarts-div">
    <div class="flex a-c j-s">
      <div class="qps-title">用户行为监控</div>
      <div class="icon" style="font-size: 20px" @click="jiaziClick()">
        <el-icon :size="20" :class="{'is-loading':timer.flag}" @click="getActionList">
          <Refresh style="width: 1em; height: 1em" />
        </el-icon>
        
        <!-- <i
          :class="[timer.flag ? 'jiazai' : '', 'iconfont', 'unify-jiazai']"
        ></i> -->
      </div>
    </div>
    <div class="content">
      <el-timeline>
        <el-timeline-item v-for="(item, index) in listDto.list" :key="index">
          <!-- <template #dot>
                333
            </template> -->
          <div class="row">
            <div class="label">
              {{ item.keyword }}
            </div>
            <div class="flex a-c">
              <span class="text">{{ item.createdTimeStr }}</span>
              <span class="text" v-if="item.userName">{{ item.userName }}</span>
              <span class="text">{{ item.ip }}</span>
            </div>
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs, onBeforeUnmount } from "vue";
import { findHomeWaterfallFlow } from "@/js/network/main";

const timer = reactive({
  timer: null,
  time: 10000,
  flag: false,
});

const listDto = reactive({
  loading: false,
  list: [
    
  ],
});

function jiaziClick() {}

// 请求
function getActionList() {
  if (timer.flag) return;
  timer.flag = true;

  findHomeWaterfallFlow({top:50}).then(res => {

    let aTimer = setTimeout(() => {
      timer.flag = false;
      clearTimeout(aTimer);
    },1000)

    
    if (!res || !res.data) {
       return;
     }
    listDto.list = res.data;
  }).catch(error => {
     timer.flag = false;
  })

  // 请求

//   timer.flag = false;
}

function initTimer() {
  timer.timer = setInterval(() => {
    getActionList();
  }, timer.time);
}

// onBeforeUnmount(() => {
//     {
//     if(timer.timer){
//         clearInterval(timer.timer)
//     }
// }
// })

initTimer();
getActionList();
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.echarts-div {
  width: 100%;
  height: 330px;
  background: #f8f9fd;
  border-radius: 8px;
  border: 1px solid #dce1f0;
  padding: 18px 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.qps-title {
  font-weight: bold;
  font-size: 18px;
  color: #102a74;
}
.icon {
  color: #5f5f5f;
}

.little-echarts-container {
  width: 100%;
  height: 120px;
  background: #f8f9fd;
  border-radius: 8px;
  border: 1px solid #dce1f0;
  padding: 18px 24px;
  box-sizing: border-box;
  margin-top: 20px;
}
.little-echarts {
  width: 50%;
  margin-right: 30px;
}
.title {
  font-size: 12px;
  color: #5f5f5f;
  margin-bottom: 5px;
}
.jiazai {
  animation: rotate-ley 400ms ease;
}

@keyframes rotate-ley {
  0% {
    rotate: 0;
  }
  100% {
    rotate: 360deg;
  }
}

.content {
  padding: 10px;
  padding-top: 20px;
  overflow-y: scroll;
  flex: 1;
  .el-timeline {
    padding-left: 0px;
  }

  .row {
    .label {
      color: #333;
      margin-bottom: 8px;
      font-weight: bold;
    }
    .text {
      color: #5f5f5f;
      // width: 120px;
    //   flex: 1;
      width: 33%;
    }
  }
}
</style>
