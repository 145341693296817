<template>
  <div class="baseInfo">
    <div class="main-row flex">
      <div style="flex: 1">
        <div class="title-name main-row">数据库名称</div>

        <div class="main-row flex a-c">
          <div class="row-tip">展示名称</div>
          <div class="input-container" style="font-weight: 600">
            <el-input v-model="state.params.name" show-word-limit maxlength="200" placeholder="请输入数据库名称"/>
          </div>
        </div>
        <div class="main-row flex">
          <div class="row-tip">别名/曾用名</div>
          <div class="input-container">
            <div class="input-div" v-for="(item,index) in state.aliasList">
              <el-input v-model="item.name" show-word-limit maxlength="200" placeholder="请输入数据库别名/曾用名"/>
              <i class="iconfont unify-shanchu" @click="deleteOne(index)" v-if="index!=0"></i>
            </div>
            <div class="add-tip" @click="addLinkArr">添加一个别名/曾用名</div>

          </div>
        </div>
      </div>
      <div class="main-row database-logo" style="width: 230px">
        <div class="title-name" style="margin-bottom: 20px">数据库LOGO</div>
        <el-upload
            class="avatar-uploader"
            action="action"
            :http-request="changeImage"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            accept=".jpg,.png,.jpeg,.gif"
        >
          <div class="avatar" v-if="state.params.logoPath" :style="`background:url(${state.params.logoPath})`"></div>
          <el-icon v-else class="avatar-uploader-icon">
            <Plus/>
          </el-icon>
        </el-upload>
      </div>
    </div>
    <div class="main-row">
      <div class="title-name" style="margin-bottom: 20px">资源简介</div>
      <tinyMceEditor :defaultId="'resourceIntroduction'" key="resourceIntroduction"
                     ref="resourceIntroduction"
                     @update:modelValue="(val)=>updateContent('resourceIntroduction',val)"/>
    </div>
    <div class="main-row">
      <div class="title-name" style="margin-bottom: 20px">资源详情</div>
      <tinyMceEditor :defaultId="'resourceDetails'" key="resourceDetails"
                     ref="resourceDetails"
                     @update:modelValue="(val)=>updateContent('resourceDetails',val)"/>
    </div>

    <el-affix position="bottom" :offset="20">
      <div class="button-container flex a-c j-c">
        <el-button color="#626aef" @click="submit">保存</el-button>
        <el-button color="#626aef" plain @click="reset">重置</el-button>
      </div>
    </el-affix>

  </div>
</template>

<script>
import tinyMceEditor from "@/components/untils/tinyMceEditor"
import {reactive, ref, onMounted, nextTick} from "vue";
import {useRoute} from 'vue-router'
import {Plus} from '@element-plus/icons-vue';
import {ElMessage, ElMessageBox} from 'element-plus';
import {
  findOneManagerDatabaseDetailsById,
  saveManagerDatabaseDetailsBaseInfo,
  uploadFileByMultipartFile
} from "@/js/network/main"
import {MessageBox} from "@/js/utils/commonJs";

export default {
  components: {
    tinyMceEditor,
    Plus
  },
  setup(props, {attrs, slots, emit, expose}) {
    const route = useRoute();

    const resourceDetails = ref(null);
    const resourceIntroduction = ref(null);

    let state = reactive({
      params: {
        name: "",
        logoPath: "",
        resourceIntroduction: "",
        resourceDetails: ""
      },
      aliasList: [],
    });

    let stateCopy = {
      params: {
        name: "",
        logoPath: "",
        resourceIntroduction: "",
        resourceDetails: ""
      },
      aliasList: [],
    }
    const updateContent = (key, val) => {
      state.params[key] = val;
    }
    const handleAvatarSuccess = function (response, uploadFile) {

    }
    const beforeAvatarUpload = function (rawFile) {
      
      const isJPG = rawFile.type == "image/jpeg"  || rawFile.type == "image/png" || rawFile.type == "image/jpg" || rawFile.type == "image/gif";
      if (!isJPG) {
        ElMessage.error('图片必须是jpeg,png,jpg,gif格式!')
        return false
      } else if (rawFile.size / 1024 / 1024 > 2) {
        ElMessage.error('照片大小不得超过2MB!')
        return false
      }
      return true
    }
    const changeImage = function (param) {
      console.log('param上传图片 ', param);
      var form = new FormData();
      form.append("file", param.file);

      uploadFileByMultipartFile(form)
          .then((res) => {
            if (!res.success) {
              MessageBox.error(res.msg);
              return;
            }
            state.params.logoPath = res.data.url;
          })
          .catch((error) => {
            //MessageBox.error(error);
            console.log(error, "catchError");
          });
    }
    const deleteOne = function (index) {
      state.aliasList.splice(index, 1);
    }
    const addLinkArr = function () {
      state.aliasList.push({})
    }
    const submit = () => {
      ElMessageBox.confirm('确定要执行本操作吗?').then((aaa) => {
        if (aaa) {
          let params = {
            ...state.params,
            aliasList: state.aliasList.map(r => r.name),
            id: route.query.id,
            dId: route.query.dId
          }
          saveManagerDatabaseDetailsBaseInfo(params).then(res => {
            if (!res.success) {
              MessageBox.error(res.msg);
              return;
            }
            MessageBox.success("保存成功！");
            // window.location.reload();
          }).catch(error => {
            console.log(error, "catchError");
          });
        }
      }).catch(() => {

      })

    }
    const reset = () => {
      state.params = JSON.parse(JSON.stringify(stateCopy.params || {}));
      state.aliasList = JSON.parse(JSON.stringify(stateCopy.aliasList || []));
      resourceIntroduction.value.handleSetContent(state.params.resourceIntroduction || "");
      resourceDetails.value.handleSetContent(state.params.resourceDetails || "");

    }

    const init = function () {
      let {id, dId} = route.query
      findOneManagerDatabaseDetailsById({id, dId}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        if (!res || !res.data) {
          return;
        }
        let arr = [];
        if (res.data.aliasList && res.data.aliasList.length > 0) {
          res.data.aliasList.map(r => {
            let obj = {}
            obj.name = r;
            arr.push(obj);
          });
        } else {
          arr.push({});
        }
        state.params = res.data;
        state.aliasList = arr;

        stateCopy.params = {...res.data};
        stateCopy.aliasList = [...arr];
        console.log('state.params: ', state.params);
        nextTick(() => {
          resourceIntroduction.value.handleSetContent(state.params.resourceIntroduction || "");
          resourceDetails.value.handleSetContent(state.params.resourceDetails || "");
        })

      }).catch(error => {
        //MessageBox.error(error);
        console.log(error, "catchError");
      });
    }
    onMounted(() => {
      init();
    });


    return {
      resourceIntroduction,
      resourceDetails,
      state,
      updateContent,
      init,
      handleAvatarSuccess,
      beforeAvatarUpload,
      changeImage,
      deleteOne,
      addLinkArr,
      submit,
      reset,
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.baseInfo {
  position: relative;
  padding-bottom: 38px;

  .button-container {
    padding: 10px 0;
    background-color: white;
  }
}

.title-name {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
  color: #232323;
}

//.database-logo{
//  position: absolute;
//  top: -5px;
//  right: 20px;
//}
.main-row {
  margin-bottom: 25px;
}

.row-tip {
  font-size: 14px;
  font-weight: 600;
  color: #232323;
  width: 120px;
}

.input-container {
  width: 600px;
  padding-right: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input-div {
  position: relative;
  margin-bottom: 8px;

  .iconfont {
    position: absolute;
    right: -20px;
    top: 8px;
    cursor: pointer;
    color: #333333;
  }
}

//.avatar-uploader{
//  width: 200px;
//  height: 135px;
//}
//:deep(.avatar-uploader-icon){
//  width: 200px;
//  height: 135px;
//}


.avatar-uploader .avatar {
  width: 200px;
  height: 135px;
  border: 1px solid #DED9D9;
  border-radius: 9px 9px 9px 9px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

:deep(.avatar-uploader .el-upload) {
  border: 1px dashed var(--el-border-color) !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 188px;
  height: 130px;
  text-align: center;
}

.add-tip {
  font-size: 14px;
  color: #3157b9;
  cursor: pointer;
  user-select: none;
}

</style>
