<template>
  <div class="baseInfo">

    <el-table :data="attachmentList"
              border
              max-height="calc(100vh - 300px)"
              stripe style="width: 100%"
              class="my-table">
      <el-table-column prop="date" label="附件名称">
        <template #default="scope">
          <div class="flex" style="flex-wrap: wrap">
            {{ scope.row.name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="创建时间">
        <template #default="scope">
          <div class="flex" style="flex-wrap: wrap">
            {{ scope.row.createdTimeStr }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" align="center">
        <template #default="scope">
          <span class="edit-text-button" @click="copyText(scope.row)">复制</span>
          <span class="delete-text-button" @click="deleteOne(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="add-tip">
      <span @click="addLinkHandler">添加附件</span>
    </div>


    <addDialog @success="success" ref="addDialogComp"/>
  </div>
</template>

<script>
import {
  findListByUnifyVdatabaseDetailsId,
  deleteAttachmentById,
} from "@/js/network/main/index.js"
import {reactive, ref} from "vue";
import {useRoute} from "vue-router";
import {MessageBox} from "@/js/utils/commonJs";
import {ElMessageBox} from "element-plus";
import addDialog from "./addDialog";
import Clipboard from 'clipboard';

export default {
  components: {
    addDialog
  },
  setup() {
    const route = useRoute();
    const attachmentList = ref([]);
    let attachmentListCopy = [];
    const addDialogComp = ref(null);
    //添加成功按钮
    const success = function (item) {
      init();
    }
    //添加
    const addLinkHandler = function () {
      addDialogComp.value.init();
    }
    //删除一个
    const deleteOne = function (item) {
      ElMessageBox.confirm('确定要执行本操作吗?').then((aaa) => {
        deleteAttachmentById({id:item.id}).then(res => {
          if (!res.success) {
            MessageBox.error(res.msg);
            return;
          }
          MessageBox.success("操作成功！");
          init();
        }).catch(error => {
          //MessageBox.error(error);
          console.log(error, "catchError");
        });
      }).catch(() => {
      })

    }
    //复制链接
    const copyText = function (row){
      const textToCopy = row.url;
      const clipboard = new Clipboard('.btn', {
        text: () => textToCopy
      });

      clipboard.on('success', () => {
        MessageBox.success('链接已成功复制到剪贴板');
        clipboard.destroy();
      });

      clipboard.on('error', () => {
        MessageBox.error('复制失败');
        clipboard.destroy();
      });

      // 触发复制动作
      clipboard.onClick(event);
    }

    //获取数据
    const init = function () {
      let {id} = route.query
      findListByUnifyVdatabaseDetailsId({unifyVdatabaseDetailsId: id}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        if (res && res.data) {
          attachmentList.value = res.data;
          attachmentListCopy = JSON.parse(JSON.stringify(res.data));
        }
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error, "catchError");
      });
    }
    //重置
    const reset = () => {
      attachmentList.value = JSON.parse(JSON.stringify(attachmentListCopy));
    }
    init();
    return {
      attachmentList,
      copyText,
      addDialogComp,
      success,
      reset,
      addLinkHandler,
      deleteOne,
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-row {
  margin-bottom: 8px;
}

.button-container {
  padding: 10px 0;
  background-color: white;
}


.edit-text-button {
  font-size: 14px;
  color: #3556fc;
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 12px;
}
.delete-text-button{
  font-size: 14px;
  color: red;
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
}
.add-tip {
  font-size: 14px;
  color: #3157b9;
  cursor: pointer;
  user-select: none;
  margin-top: 12px;
}

</style>
