<template>
  <div>
    <el-dialog
      v-model="visible"
      :width="widthCom"
      :close-on-click-modal="closeOnClickModal"
      :close-on-press-escape="closeOnPressEscape"
      :top='top'
      :title="title"
      v-bind="$attrs"
    >
        <template v-for="slotName in Object.keys($slots)" #[slotName]>
            <slot :name="slotName"></slot>
        </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive , computed } from "vue";

// 双向绑定机制  初始化类型 默认值
const visible = defineModel({ default: false, type: Boolean });

const props = defineProps({
    width:{
        type:[String , Number],
        default:""
    },
    size:{
        // medium / small / mini
        type:[String],
        default:'medium'
    },
    closeOnClickModal:{
        type:Boolean,
        default:false
    },
    closeOnPressEscape:{
        type:Boolean,
        default:false
    },
    top:{
        type:String,
        default:'10vh'
    },
    second:{
        type:Boolean,
        default:false
    },
    title:{
        type:String,
        default:""
    }
});

const sizeMap = ref({
    big:"1000px",
    medium:"800px",
    small:"600px",
    mini:"400px"
})

const widthCom = computed( () => {
    return props.width || sizeMap.value[props.size]
})
</script>

<style lang="scss" scoped>
</style>
