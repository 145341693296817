const state = {
    //用户数据
    userData: {}
}

const mutations = {
    setUserData(state, val) {
        state.userData = val;
    }
}

const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}