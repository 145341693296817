<template>
  <div class="promotionAmbassador">
    <div>
      <div class="table-title flex a-c">
        <div class="title-text flex a-c"><i class="iconfont unify-a-people-copymembers"></i>推广人员</div>
        <div class="flex">
          <div class="title-button" @click="showPopularizeDialog"><i class="iconfont unify-recruit"></i>
            达人招募
            <span class="subscript" v-if="waitAuditCount">
              {{ waitAuditCount > 99 ? 99 : waitAuditCount}}
            </span>
          </div>
          <div class="title-button" @click="showDialog"><i class="iconfont unify-a-generaluser-add"></i>新增人员</div>
        </div>
      </div>
      <el-table :data="state.tableData"
                max-height="calc(100vh - 430px)"
                stripe style="width: 100%" class="my-table" v-loading="tableLoading">
        <el-table-column label="序号" width="80px" align="center">
          <template #default="scope">
            {{scope.$index + 1}}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="推广人员" align="center">
          <template #default="scope">
            {{scope.row.name || '-'}}
          </template>
        </el-table-column>

        <el-table-column prop="num" label="推广次数" align="center">
          <template #default="scope">
            {{ scope.row.num || 0 }}
          </template>
        </el-table-column>
        <el-table-column label="曝光量" align="center" width="100px">
          <template #default="scope">
            {{ scope.row.exposureNum || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="loginTimeStr" label="最近登录时间" align="center">
          <template #default="scope">
            {{ scope.row.loginTimeStr || "-" }}
          </template>
        </el-table-column>

        <el-table-column prop="address" label="操作" align="center" fixed="right" width="220px">
          <template #default="scope">
            <span class="config-text-button" @click="showCopyMessage(scope.$index, scope.row)">平台访问账号</span>
            <span class="edit-text-button" @click="deleteOne(scope.$index, scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex a-c" style="justify-content: flex-end;margin-top: 20px">
        <span style="margin-right: 12px;color: #6573A2;font-size: 16px">总数：{{ total }}</span>
        <div class="page-button-pre" @click="handlePageClick(false)">上一页</div>
        <el-pagination
            background
            layout="pager,sizes"
            v-model:page-size="paramsFilter.pageSize"
            :page-sizes="[5,10,20]"
            :current-page="paramsFilter.pageNum"
            @current-change="handlerPageNumChange"
            @size-change="handlerPageSizeChange"
            :total="total"/>
        <div class="page-button-next" @click="handlePageClick(true)">下一页</div>
      </div>
    </div>
    <addDialog @success="success" ref="addDialogComp"/>
    <messageDialog ref="messageDialogComp"/>

    <!-- 达人招募 -->
    <talentRecruitmentDialog
      v-model="talentRecruitmentFlag"
      @update="updataFn"
    >
    </talentRecruitmentDialog>
  </div>
</template>

<script>
import {findPromotionUserListPage, deleteById , countAuditNum} from "@/js/network/main"
import {reactive, toRefs, ref, isReactive} from "vue";
import router from "@/router"
import addDialog from "./addDialog";
import messageDialog from "./messageDialog";
import {MessageBox} from "@/js/utils/commonJs";
import talentRecruitmentDialog from './components/talentRecruitmentDialog.vue'


export default {
  components: {
    addDialog,
    messageDialog,
    talentRecruitmentDialog
  },

  setup(props, context) {
    const talentRecruitmentFlag = ref(false);
    const addDialogComp = ref(null);
    const messageDialogComp = ref(null);

    const waitAuditCount = ref(0);

    const showPopularizeDialog = function(){
      talentRecruitmentFlag.value = true;
    }

    const showDialog = function(){
      addDialogComp.value.init();
    }
    const showCopyMessage = function (index, row) {
      messageDialogComp.value.init(row);
    }
    const success = function(){
      getTable();
    }
    let tableLoading = ref(true);
    let paramsFilter = reactive({ // 筛选参数
      pageSize: 10,//当前页大小
      pageNum: 1,//当前页
    });
    let state = reactive({
      tableData: [],
    })
    let total = ref(0);
    
    //初始化操作
    const getTable = function () {
      tableLoading.value = true;
      findPromotionUserListPage(paramsFilter).then(res => {
        if (!res || !res.data) {
          tableLoading.value = false;
          return;
        }
        state.tableData = res.data;
        total.value = parseInt(res.count);
        tableLoading.value = false;
      }).catch(error => {
        if (error.message == "Request Canceled!") {
          return
        }
        tableLoading.value = false;
      });

    }

    //筛选并请求
    const handlerFilterRequestClick = function (key, value) {
      paramsFilter[key] = value;
      paramsFilter.pageNum = 1;
      getTable();
    }

    //表格头组件自定义事件
    const handlerFilterNotRequestClick = function (key, value) {
      paramsFilter[key] = value;
      getTable();
    }

    const handlerPageNumChange = function (num) {
      paramsFilter.pageNum = num;
      getTable();
    }
    const handlerPageSizeChange = function (num) {
      paramsFilter.pageSize = num;
      paramsFilter.pageNum = 1;
      getTable();
    }

    // 请求待审核数量
    const getCountAuditNum = function(){
      countAuditNum().then( res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        waitAuditCount.value = res.data || 0;
        
      }).catch(error => {
        console.log(error, "catchError");
      });
    }

    const updataFn = () => {
      getCountAuditNum();
      getTable();
    }

    const deleteOne = function (index, row) {
      deleteById({id: row.id}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        MessageBox.success("操作成功！");
        getTable();
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error, "catchError");
      });
    }
    const handlePageClick = function (flag) {
      flag ? paramsFilter.pageNum++ : paramsFilter.pageNum--;
      if(paramsFilter.pageNum<1){
        paramsFilter.pageNum = 1;
        return;
      }
      let max = Math.ceil(total.value/paramsFilter.pageSize);
      if(paramsFilter.pageNum>max){
        paramsFilter.pageNum = max;
        return;
      }
      getTable();
    }
    
    getTable();

    // 待审核数量
    getCountAuditNum();

    return {
      tableLoading,
      paramsFilter,
      state,
      total,
      getTable,
      handlerFilterRequestClick,
      handlerFilterNotRequestClick,
      handlePageClick,
      handlerPageNumChange,
      handlerPageSizeChange,
      showCopyMessage,
      deleteOne,
      addDialogComp,
      messageDialogComp,
      success,
      showDialog,
      showCopyMessage,
      talentRecruitmentFlag,
      showPopularizeDialog,
      waitAuditCount,
      getCountAuditNum,
      updataFn
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.unify-iconfontxiaogantanhao {
  margin-left: 8px;
  font-weight: 600;

  &.red {
    color: red;
  }

  &.yellow {
    color: #fca506;
  }
}

.promotionAmbassador {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 20px 0 0 0;
}
.table-title{
  margin-bottom: 15px;
  justify-content: space-between;
}
.title-text{
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  .iconfont{
    color: #2054D6;
    font-size: 22px;
    margin-right: 5px;
  }
}
.title-color{
  background: #FFEFEF;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #FF0000;
  padding: 8px;
  box-sizing: border-box;
  font-size: 14px;
  color: #FF0000;
  margin-left: 15px;
}
.title-button{
  width: 110px;
  height: 36px;
  background: #2054D6;
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  line-height: 36px;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  // margin-left: auto;
  margin-right: 18px;
  cursor: pointer;
  position: relative;
  left: 0;
  top: 0;
  .iconfont{
    margin-right: 5px;
  }

  :last-child{
    margin-right: 0;
  }

  .subscript{
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 5;

    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #FC3535;
    font-size: 12px;
    border-radius: 50%;

  }
}

.config-text-button {
  font-size: 14px;
  color: #1239A6;
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 8px;
}

.edit-text-button {
  font-size: 14px;
  color: #FC3535;
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
}

.my-table {
  border: 1px solid #DCE1F0;
  border-radius: 10px 10px 10px 10px;
}

.page-button-next {
  width: 72px;
  height: 40px;
  background: #EEF2FE;
  border-radius: 10px 10px 10px 10px;
  font-size: 16px;
  color: #6573A2;
  line-height: 40px;
  text-align: center;
  opacity: 1;
  border: 1px solid #DCE1F0;
  user-select: none;
  cursor: pointer;
  margin-left: 15px;
}

.page-button-pre {
  width: 72px;
  height: 40px;
  background: #EEF2FE;
  border-radius: 10px 10px 10px 10px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #6573A2;
  opacity: 1;
  border: 1px solid #DCE1F0;
  user-select: none;
  cursor: pointer;
  margin-right: 15px;
}

:deep(.el-table__header) {


  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  height: 64px;

  .el-table__cell {
    background: #EEF2FE !important;
    color: #6573A2;
  }
}

:deep(.el-table__row) {
  height: 64px;

  .el-table__cell {
    color: #6573A2;
  }
}

:deep(.el-table__row--striped) {

  .el-table__cell {
    background: #F8F9FD !important;
  }
}

:deep(.el-pager) {
  .number {
    font-size: 14px;
    color: #6573A2;
    background: none !important;
  }

  .more {
    background: none !important;
  }

  .is-active {
    width: 40px;
    height: 40px;
    background: #2054D6 !important;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    border: 1px solid #DCE1F0;
  }
}

</style>
