<template>
  <div class="ResourcePromotionManagement">
    <div class="count-container" v-loading="statisticsLoading">
      <div class="count-container-item flex a-c">
        <img class="count-img" src="https://miniopreview.resourcemap.com.cn/ersmap-platform/system/unify/icon-line.png"/>
        <div>
          <div class="count-container-item-name">推广大使</div>
          <div class="count-container-item-count">{{state.staticCount.promotionUserNum || 0}}</div>
        </div>
      </div>
      <div class="count-container-item flex a-c">
        <img class="count-img" src="https://miniopreview.resourcemap.com.cn/ersmap-platform/system/unify/icon-text.png"/>
        <div>
          <div class="count-container-item-name">累计推广次数</div>
          <div class="count-container-item-count">{{state.staticCount.totalPromotionRecordNum || 0}}</div>
        </div>
      </div>
      <div class="count-container-item flex a-c">
        <img class="count-img" src="https://miniopreview.resourcemap.com.cn/ersmap-platform/system/unify/icon-people.png"/>
        <div>
          <div class="count-container-item-name">推广资源曝光量</div>
          <div class="count-container-item-count">{{state.staticCount.totalExposureNum || 0}}</div>
        </div>
      </div>
    </div>
    <div class="table-container">
      <div class="table-title flex a-c">
        <div class="title-text"><i class="iconfont unify-dinggouguanli"></i>资源推广记录</div>
        <div style="margin-left: auto">
          <el-select v-model="paramsFilter.promotionUserId" filterable clearable placeholder="推广大使"
                     @change="getTable"
                     style="width: 160px">
            <el-option :class="item.deleteFlag==1?'delete-user':''" :key="0" :label="item.name" :value="item.id" v-for="item in state.userList"/>
          </el-select>
        </div>
        <div style="margin-left: 25px">
          <el-select v-model="paramsFilter.state" filterable clearable placeholder="状态筛选"
                     @change="getTable"
                     style="width: 160px">
            <el-option :key="0" label="待审核" :value="0"/>
            <el-option :key="1" label="审核通过" :value="1"/>
            <el-option :key="2" label="审核驳回" :value="2"/>
            <el-option :key="3" label="管理员删除" :value="3"/>
          </el-select>
        </div>
        <div class="title-button" @click="promoteFunction" v-loading="exportLoading"
        ><i class="iconfont unify-a-interactive-buttonshare-external"></i>导出推广记录</div>
      </div>
      <el-table :data="state.tableData"
                max-height="calc(100vh - 470px)"
                stripe style="width: 100%" class="my-table" v-loading="tableLoading">

        <el-table-column label="序号" align="center" width="80px">
          <template #default="scope">
            {{scope.$index+1}}
          </template>
        </el-table-column>

        <el-table-column label="资源名称" align="left">
          <template #default="scope">
            <div class="overtext">
              {{ scope.row.resourceName || '-' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="资源类型" align="center" width="120px">
          <template #default="scope">
            {{ scope.row.datatypeStr || '-' }}
          </template>
        </el-table-column>
        <el-table-column label="推广大使" align="center" width="120px">
          <template #default="scope">
            {{ scope.row.name || '-' }}
          </template>
        </el-table-column>
        <el-table-column label="发布时间" align="center" width="230px">
          <template #default="scope">
            {{ scope.row.createdTimeStr || '-' }}
          </template>
        </el-table-column>
        <el-table-column label="曝光量" align="center" width="100px">
          <template #default="scope">
            {{ scope.row.exposureNum || '-' }}
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" width="100px">
          <template #default="scope">
            {{ scope.row.stateStr || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="address" label="是否开启" align="center" fixed="right" width="100px">
          <template #default="scope">
            {{ scope.row.openFlagStr || '-' }}
          </template>
        </el-table-column>

        <el-table-column prop="address" label="操作" align="right" fixed="right" width="220px" >
          <template #default="scope" >
            <span class="config-text-button" @click="configDetailClick(scope.$index, scope.row)" v-if="scope.row.state==0">审核</span>
            <span class="config-text-button" @click="showDetail(scope.$index, scope.row)">查看</span>
            <span class="edit-text-button" @click="cancelFirst(scope.$index, scope.row)" v-if="scope.row.state!=3">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex a-c" style="justify-content: flex-end;margin-top: 20px">
        <span style="margin-right: 12px;color: #6573A2;font-size: 16px">总数：{{ total }}</span>
        <div class="page-button-pre" @click="handlePageClick(false)">上一页</div>
        <el-pagination
            background
            layout="pager,sizes"
            v-model:page-size="paramsFilter.pageSize"
            :page-sizes="[5,10,20]"
            :current-page="paramsFilter.pageNum"
            @current-change="handlerPageNumChange"
            @size-change="handlerPageSizeChange"
            :total="total"/>
        <div class="page-button-next" @click="handlePageClick(true)">下一页</div>
      </div>

    </div>
    <examine ref="examineComp" @success="getTable"/>
    <showDetail ref="showDetailComp"/>
  </div>
</template>

<script>
import {findPromotionRecordListPage,findPromotionUserDropDownList, deletePromotionRecordById,findOnePromotionRecordStatistics} from "@/js/network/main"
import {exportPro} from "@/js/network/main"
import {download} from "@/js/utils/download"
import {reactive, toRefs, ref, isReactive} from "vue";
import router from "@/router"
import {MessageBox} from "@/js/utils/commonJs";
import examine from "./examine"
import showDetail from "./showDetail"


export default {
  components:{
    examine,
    showDetail
  },
  setup(props, context) {
    let tableLoading = ref(true);
    let statisticsLoading = ref(true);
    let exportLoading = ref(false);
    let userLoading = ref(true);
    const examineComp = ref(null);
    const showDetailComp = ref(null);
    let paramsFilter = reactive({ // 筛选参数
      promotionUserId: "",//搜索关键词
      state:"",
      pageSize:10,
      pageNum:1
    });
    let state = reactive({
      tableData: [],
      userList: [],// 表头筛选枚举
      staticCount:{}
    })
    let total = ref(0);
    //初始化操作
    const getTable = function () {
      tableLoading.value = true;
      findPromotionRecordListPage(paramsFilter).then(res => {
        if (!res || !res.data) {
          tableLoading.value = false;
          return;
        }
        state.tableData = res.data;
        total.value = parseInt(res.count);
        tableLoading.value = false;
      }).catch(error => {
        if (error.message == "Request Canceled!") {
          return
        }
        tableLoading.value = false;
      });

    }
    const getRecordStatistics = function () {
      statisticsLoading.value = true;
      findOnePromotionRecordStatistics().then(res => {
        if (!res || !res.data) {
          statisticsLoading.value = false;
          return;
        }
        state.staticCount = res.data;
        statisticsLoading.value = false;
      }).catch(error => {
        statisticsLoading.value = false;
        if (error.message == "Request Canceled!") {
          return
        }
      });

    }
    const getPromotionUser = function () {
      userLoading.value = true;
      findPromotionUserDropDownList().then(res => {
        if (!res || !res.data) {
          userLoading.value = false;
          return;
        }
        state.userList = res.data;
        userLoading.value = false;
      }).catch(error => {
        if (error.message == "Request Canceled!") {
          return
        }
        userLoading.value = false;
      });

    }


    //筛选并请求
    const handlerFilterRequestClick = function (key, value) {
      paramsFilter[key] = value;
      paramsFilter.pageNum = 1;
      getTable();
    }

    //表格头组件自定义事件
    const handlerFilterNotRequestClick = function (key, value) {
      paramsFilter[key] = value;
      getTable();
    }

    const handlerPageNumChange = function (num) {
      paramsFilter.pageNum = num;
      getTable();
    }
    const handlerPageSizeChange = function (num) {
      paramsFilter.pageSize = num;
      paramsFilter.pageNum = 1;
      getTable();
    }

    const configDetailClick = function (index, row) {
      examineComp.value.init(row);
    }
    const cancelFirst = function (index, row) {
      deletePromotionRecordById({id: row.id}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        MessageBox.success("操作成功！");
        getTable();
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error, "catchError");
      });
    }
    const showDetail = function (index, row){
      showDetailComp.value.init(row);
    }
    const handlePageClick = function (flag) {
      flag ? paramsFilter.pageNum++ : paramsFilter.pageNum--;
      if(paramsFilter.pageNum<1){
        paramsFilter.pageNum = 1;
        return;
      }
      let max = Math.ceil(total.value/paramsFilter.pageSize);
      if(paramsFilter.pageNum>max){
        paramsFilter.pageNum = max;
        return;
      }
      getTable();
    }
    const promoteFunction = function(){
      exportLoading.value = true;
      exportPro({state:paramsFilter.state,promotionUserId:paramsFilter.promotionUserId}).then(res => {
        exportLoading.value = false;
        download(res,"",'excel');
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error, "catchError");
      });
    }

    //setup 不需要create生命周期，此方法就相当于是create生命周期
    getTable();
    getRecordStatistics();
    getPromotionUser();

    return {
      tableLoading,
      statisticsLoading,
      userLoading,
      examineComp,
      showDetailComp,
      paramsFilter,
      state,
      total,
      getTable,
      exportLoading,
      getRecordStatistics,
      handlerFilterRequestClick,
      handlerFilterNotRequestClick,
      handlePageClick,
      handlerPageNumChange,
      handlerPageSizeChange,
      configDetailClick,
      cancelFirst,
      showDetail,
      promoteFunction
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.unify-iconfontxiaogantanhao {
  margin-left: 8px;
  font-weight: 600;

  &.red {
    color: red;
  }

  &.yellow {
    color: #fca506;
  }
}

.ResourcePromotionManagement {
  padding: 20px;
}
.count-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 50px 37px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 20px;
  margin-bottom: 20px;
}
.count-container-item{

}
.count-img{
  width: 60px;
  margin-right: 20px;
}
.count-container-item-name{
  font-size: 12px;
  color: #86909C;
}
.count-container-item-count{
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  font-weight: bold;
  font-size: 32px;
  color: #000000;
}
.table-container{
  background-color: #ffffff;
  border-radius: 20px;
  padding: 29px 20px;
}
.table-title{
  margin-bottom: 15px;
}
.delete-user{
  text-decoration: line-through;
  color: #949494;
}
.title-text{
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  .iconfont{
    color: #2054D6;
    margin-right: 5px;
  }
}
.title-color{
  background: #FFEFEF;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #FF0000;
  padding: 8px;
  box-sizing: border-box;
  font-size: 14px;
  color: #FF0000;
  margin-left: 15px;
}
.title-button{
  width: 146px;
  height: 36px;
  background: #2054D6;
  border-radius: 10px 10px 10px 10px;
  text-align: center;
  line-height: 36px;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
  margin-left: 25px;
}
.overtext{
  overflow: hidden;
  display: -webkit-box; //使用自适应布局
  -webkit-line-clamp: 1; //设置超出行数，要设置超出几行显示省略号就把这里改成几
  -webkit-box-orient: vertical;
}

.config-text-button {
  font-size: 14px;
  color: #1239A6;
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 8px;
}

.edit-text-button {
  font-size: 14px;
  color: #FC3535;
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 8px;
}

.my-table {
  border: 1px solid #DCE1F0;
  border-radius: 10px 10px 10px 10px;
}

.page-button-next {
  width: 72px;
  height: 40px;
  background: #EEF2FE;
  border-radius: 10px 10px 10px 10px;
  font-size: 16px;
  color: #6573A2;
  line-height: 40px;
  text-align: center;
  opacity: 1;
  border: 1px solid #DCE1F0;
  user-select: none;
  cursor: pointer;
  margin-left: 15px;
}

.page-button-pre {
  width: 72px;
  height: 40px;
  background: #EEF2FE;
  border-radius: 10px 10px 10px 10px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #6573A2;
  opacity: 1;
  border: 1px solid #DCE1F0;
  user-select: none;
  cursor: pointer;
  margin-right: 15px;
}

:deep(.el-table__header) {


  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  height: 64px;

  .el-table__cell {
    background: #EEF2FE !important;
    color: #6573A2;
  }
}

:deep(.el-table__row) {
  height: 64px;

  .el-table__cell {
    color: #6573A2;
  }
}

:deep(.el-table__row--striped) {

  .el-table__cell {
    background: #F8F9FD !important;
  }
}

:deep(.el-pager) {
  .number {
    font-size: 14px;
    color: #6573A2;
    background: none !important;
  }

  .more {
    background: none !important;
  }

  .is-active {
    width: 40px;
    height: 40px;
    background: #2054D6 !important;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    border: 1px solid #DCE1F0;
  }
}

</style>
