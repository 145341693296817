<template>
  <el-dialog
      v-model="state.dialogVisible"
      title="查看"
      width="700px"
      :before-close="handleClose"
  >
    <div class="main">
      <div class="resource-item cursor-pointer flex">
        <div class="book-message">
          <div class="resource-title">{{ state.rowData.resourceName }}</div>
<!--          <div class="resource-datatype">{{ state.rowData.datatypeStr }}</div>-->
        </div>
      </div>
      <div class="comment flex" v-if="state.rowData.datatypeStr">
        <div class="comment-title">资源类型</div>
        <div class="comment-content">{{state.rowData.datatypeStr}}</div>
      </div>
      <div class="comment flex" v-if="state.rowData.promotionContent">
        <div class="comment-title">推广评语</div>
        <div class="comment-content">{{state.rowData.promotionContent}}</div>
      </div>

      <div class="comment flex" v-if="state.rowData.imgPath">
        <div class="comment-title">配图</div>
        <el-image style="width: 252px; height: 174px"
                  :src="state.rowData.imgPath" fit="cover" :preview-src-list="[state.rowData.imgPath]"/>
      </div>
      <div class="comment flex" v-if="state.rowData.websiteUrl">
        <div class="comment-title">关联网址</div>
        <div class="link-url" @click="linkToUrl(state.rowData.websiteUrl)">{{state.rowData.websiteName}}</div>
      </div>
      <div class="comment flex a-c" v-if="state.rowData.reason">
        <div class="comment-title">驳回理由</div>
        <div class="comment-content">{{state.rowData.reason}}</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {reactive,ref} from 'vue'
import {audit, findOneAuditDetailById} from "@/js/network/main/index"
import {MessageBox} from "@/js/utils/commonJs";

export default {
  setup(props, {attrs, slots, emit, expose}) {
    const state = reactive({
      dialogVisible: false,
      rowData: {},
    })
    const reason = ref("");
    const boolValue = ref(true);
    const rowId = ref("")
    const userLoading = ref(false);
    const isConfirmLoading = ref(false);

    const handleClose = () => {
      state.dialogVisible = false;
      state.rowData = {};
    }
    const init = (row) => {
      state.dialogVisible = true;
      rowId.value = row.id;
      getAudit();
    }
    const getAudit = function () {
      userLoading.value = true;
      findOneAuditDetailById({id:rowId.value}).then(res => {
        if (!res || !res.data) {
          userLoading.value = false;
          return;
        }
        state.rowData = {...res.data};
        userLoading.value = false;
      }).catch(error => {
        if (error.message == "Request Canceled!") {
          return
        }
        userLoading.value = false;
      });

    }
    const linkToUrl = function (url){
        window.open(url,'_blank');
    }
    const cancleHandle = function (){
      if(boolValue.value){
        boolValue.value = false;
      }else{
        if(!reason.value){
          MessageBox.warning('请输入驳回理由!');
          return;
        }
        let params = {
          id:rowId.value,
          reason:reason.value,
          state:boolValue.value?1:2
        }
        audit(params).then(res => {
          if (!res.success) {
            MessageBox.error(res.msg);
            return;
          }
          handleClose();
          emit("success");
        }).catch(error => {
          console.log(error,"catchError");
        });
      }
    }
    const confirmHandle = function () {
      let params = {
        id:rowId.value,
        state:boolValue.value?1:2
      }
      audit(params).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        state.dialogVisible = false;
        emit("success");
      }).catch(error => {
        console.log(error,"catchError");
      });

    }
    return {
      state,
      rowId,
      userLoading,
      boolValue,
      reason,
      isConfirmLoading,
      handleClose,
      init,
      confirmHandle,
      cancleHandle,
      linkToUrl,
      ...props
    }

  }
}


</script>
<style scoped lang="scss">
.grantWater-dialog {
::v-deep .el-dialog__header {
  padding: 10px 20px 10px 20px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  color: #34495e;
  border-bottom: 1px solid #F2F3F5;
}

::v-deep .el-dialog__body {
  padding: 10px !important;
  height: auto !important;
}
}

:deep(.el-button) {
  height: 36px;
  line-height: 0px;
}

.resource-item {
  box-sizing: border-box;
  padding: 10px 10px;
  border-radius: 8px;
  margin-bottom: 27px;
}

.recommend-button {
  width: 100px;
  height: 32px;
  background: #E7EEFF;
  border-radius: 4px 4px 4px 4px;
  text-align: center;
  line-height: 32px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 14px;
  color: #2054D6;
  transition: opacity 300ms ease;

.iconfont {
  font-size: 14px;
  margin-left: 3px;
}
}

.resource-item-img {
  width: 128px;
  height: 166px;
  border-radius: 4px;
  position: relative;
  margin-right: 20px;
  overflow: hidden;
}

.carbon-img {
  width: 128px;
  height: 166px;
  cursor: pointer;
  border-radius: 4px;
}

.resource-title {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  margin-bottom: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.row-gray {
  font-size: 14px;
  color: #999999;

.tip {

}
}

.row-content {
  display: inline-block;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  font-size: 14px;
  color: #000000;
}

.resource-author {
  margin-bottom: 6px;
}

.resource-year {
  margin-bottom: 6px;
}

.resource-message {
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  line-height: 22px;
//overflow: hidden;
//display: -webkit-box; //使用自适应布局
//-webkit-line-clamp: 2; //设置超出行数，要设置超出几行显示省略号就把这里改成几
//-webkit-box-orient: vertical;
  width: 100%;
}

.row-more-follow {
  display: inline-block;
  color: #1a4ddb;
}

.book-message {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.book-message-content {
  flex: 1;
  transition: opacity 300ms ease;
  display: flex;
  flex-direction: column;
}

.comment {
  padding: 0px 10px;
  margin-bottom: 30px;
}

.comment-title {
  width: 90px;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  font-weight: bold;
  font-size: 16px;
  color: #000000;

.iconfont {
  color: red;
}
}
.comment-content{
  flex: 1;
}
.link-url{
  text-decoration: underline;
  color: #1a4ddb;
  cursor: pointer;
}

.comment-textarea {
  flex: 1;
  font-family: HarmonyOS Sans SC, HarmonyOS Sans SC;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  line-height: 22px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 10px 10px;
  padding: 10px;
  box-sizing: border-box;
}

.comment-button-container {
  margin-top: 12px;
  text-align: right;

.comment-button {
  display: inline-block;
  width: 110px;
  height: 26px;
  border-radius: 6px 6px 6px 6px;
  border: 1px solid #235BE7;
  background: rgba(35,91,231,0.1);
  color: #235BE7;
  margin-left: 12px;
  text-align: center;
  line-height: 26px;
  cursor: pointer;

.iconfont {
  margin-right: 3px;
  position: relative;
  top: 1px;
}
}
}

.ersmap-loader {
  display: inline-block;
  animation: rotate-move 1s ease-in-out infinite;
}

@keyframes rotate-move {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.button-container {
  justify-content: center;
  margin-bottom: 25px;
}

.promote-button {
  display: inline-block;
  width: 120px;
  height: 36px;
  background: #235BE7;
  border-radius: 8px 8px 8px 8px;
  cursor: pointer;
  color: #ffffff;
  line-height: 36px;
  text-align: center;
  font-size: 14px;
}
.danger{
  background: red;
  margin-right: 20px;
}

.flex-div {
  padding-left: 4px;
  box-sizing: border-box;
  height: 25px;
}

.flex-block {
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  user-select: none;

.number {
  font-size: 14px;
  font-weight: 600;
}

.text {
  font-size: 14px;
  color: #000000;
}
}

.trapezoid-left {
  transform: skew(-20deg);
  padding-right: 10px;
  box-sizing: border-box;
  margin-right: -5px;

&.silent {
   background-color: #E7EEFF;

.iconfont {
  color: #235AE1;
}
}

&.recommend {
   background-color: #FCEBE9;

.iconfont {
  color: #EF6845;
}
}
}

.trapezoid-left p {
  transform: skew(20deg); /* 内容反向倾斜，使其平衡 */
}


.trapezoid-left.recommend::before {
  content: '';
  position: absolute;
  top: 0px; /* 梯形上边的偏移量，使其与底部相连 */
  left: -8px;
  background-color: #FCEBE9;
  width: 20px;
  height: 100%;
  border-radius: 4px 0 0 4px;
  transform: skew(20deg); /* 内容反向倾斜，使其平衡 */
}

.trapezoid-left.silent::before {
  content: '';
  position: absolute;
  top: 0px; /* 梯形上边的偏移量，使其与底部相连 */
  left: -8px;
  background-color: #E7EEFF;
  width: 20px;
  height: 100%;
  border-radius: 4px 0 0 4px;
  transform: skew(20deg); /* 内容反向倾斜，使其平衡 */
}

.trapezoid-right {

&.silent {
   background-color: #235AE1;
 }

&.recommend {
   background-color: #EF6845;
 }

transform: skew(-20deg);
padding-left: 10px;
box-sizing: border-box;
}

.trapezoid-right p {
  transform: skew(20deg); /* 内容反向倾斜，使其平衡 */
}

.trapezoid-right.recommend::before {
  content: '';
  position: absolute;
  top: 0px; /* 梯形上边的偏移量，使其与底部相连 */
  right: -8px;
  background-color: #EF6845;
  width: 20px;
  height: 100%;
  border-radius: 0px 4px 4px 0;
  transform: skew(20deg); /* 内容反向倾斜，使其平衡 */
}

.trapezoid-right.silent::before {
  content: '';
  position: absolute;
  top: 0px; /* 梯形上边的偏移量，使其与底部相连 */
  right: -8px;
  background-color: #235AE1;
  width: 20px;
  height: 100%;
  border-radius: 0px 4px 4px 0;
  transform: skew(20deg); /* 内容反向倾斜，使其平衡 */
}

:deep(.el-textarea__inner) {
  border: none;
  padding: 0;
}
</style>
