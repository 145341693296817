<template>
  <div class="dbNavList">
    <div class="flex a-c" style="margin-bottom: 20px;">
      <div class="input-container flex a-c" style="flex: 1">
        <el-input v-model="paramsFilter.name" placeholder="请输入馆员名称"/>
        <div class="button-container" @click="getTable"><i class="iconfont unify-sousuo"></i></div>
      </div>
      <div class="my-button" @click="showDialog">
        <i  class="iconfont jia unify-tianjia"></i>
      </div>

    </div>

    <div>
      <el-table :data="state.tableData" stripe style="width: 100%" class="my-table"
                max-height="calc(100vh - 300px)" v-loading="tableLoading">
        <el-table-column prop="date" label="馆员名称">
          <template #default="scope">
            <div class="flex" style="flex-wrap: wrap">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="称谓" align="center">
          <!--          <template #header>-->
          <!--            <unifySelect placeholder="中外类型"-->
          <!--                         @handlerSelectEvent="(value)=>handlerFilterNotRequestClick('language',value)"-->
          <!--                         :options="state.filterListObj.languageTypeList"/>-->
          <!--          </template>-->
          <template #default="scope">
            {{ scope.row.nickName || '-' }}
          </template>
        </el-table-column>
        <el-table-column label="联系邮箱" align="center">
          <template #default="scope">
            {{ scope.row.mail || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="contractEndTime" label="联系电话" align="center">
          <template #default="scope">
            {{ scope.row.phone || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="contractEndTime" label="办公地址" align="center">
          <template #default="scope">
            {{ scope.row.address || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center">
          <template #default="scope">
            <span class="edit-text-button" @click="addEditUserHandler(scope.$index, scope.row)">编辑</span>
            <span class="delete-text-button" @click="deleteUserHandler(scope.$index, scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex a-c" style="justify-content: flex-end;margin-top: 20px">
        <span style="margin-right: 12px;color: #6573A2;font-size: 16px">总数：{{ total }}</span>
        <div class="page-button-pre" @click="handlePageClick(false)">上一页</div>
        <el-pagination
            background
            layout="pager,sizes"
            v-model:page-size="paramsFilter.limit"
            :page-sizes="[5,10,20]"
            :current-page="paramsFilter.currentPage"
            @current-change="handlerPageNumChange"
            @size-change="handlerPageSizeChange"
            :total="total"/>
        <div class="page-button-next" @click="handlePageClick(true)">下一页</div>
      </div>
    </div>
    <addDialog @success="success" ref="addDialogComp"/>
  </div>
</template>

<script>
import {
  findLibrarianListPage,
  updateUserDisableFlag,
  deleteLibrarianById,
} from "@/js/network/main"
import {reactive, ref} from "vue";
import {MessageBox} from "@/js/utils/commonJs";
import unifySelect from "@/components/defineElementPlus/unifySelect"
import addDialog from "./addDialog";
import {ElMessageBox} from "element-plus";


export default {
  components: {
    unifySelect,
    addDialog
  },
  setup(props, context) {
    const addDialogComp = ref(null);
    const tableLoading = ref(true);
    let paramsFilter = reactive({ // 筛选参数
      name: "",//搜索关键词
      limit: 10,//当前页大小
      currentPage: 1,//当前页
    });
    let state = reactive({
      tableData: [],
      filterListObj: {},// 表头筛选枚举
    })
    let total = ref(0);

    //初始化操作
    const getTable = function () {
      tableLoading.value = true;
      findLibrarianListPage(paramsFilter).then(res => {
        if (!res || !res.data) {
          tableLoading.value = false;
          return;
        }
        state.tableData = res.data;
        total.value = parseInt(res.count);
        tableLoading.value = false;
      }).catch(error => {
        tableLoading.value = false;
      });

    }
    const success = function(){
      getTable();
    }

    //筛选并请求
    const handlerFilterRequestClick = function (key, value) {
      paramsFilter[key] = value;
      paramsFilter.currentPage = 1;
      getTable();
    }

    //表格头组件自定义事件
    const handlerFilterNotRequestClick = function (key, value) {
      paramsFilter[key] = value;
      getTable();
    }

    const handlerPageNumChange = function (num) {
      paramsFilter.currentPage = num;
      getTable();
    }
    const handlerPageSizeChange = function (num) {
      paramsFilter.limit = num;
      paramsFilter.currentPage = 1;
      getTable();
    }

    const updateDisAble = function (index, row) {
      updateUserDisableFlag({targetId: row.uuid}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        MessageBox.success("操作成功！");
        getTable();
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error, "catchError");
      });
    }
    const addEditUserHandler = function (index, row) {
      addDialogComp.value.init(row.id);
    }
    const deleteUserHandler = function(index,row){
      ElMessageBox.confirm('确定要执行本操作吗?').then((aaa) => {
        if (aaa) {
          deleteLibrarianById({id:row.id}).then(res => {
            if (!res.success) {
              MessageBox.error(res.msg);
              return;
            }
            getTable();
            MessageBox.success('删除成功!');
          }).catch(error => {
            //MessageBox.error(error);
            console.log(error,"catchError");
          });
        }
      }).catch(() => {
      })

    }
    const showDialog = function(){
      addDialogComp.value.init();
    }
    const handlePageClick = function (flag) {
      flag ? paramsFilter.currentPage++ : paramsFilter.currentPage--;
      if(paramsFilter.currentPage<1){
        paramsFilter.currentPage = 1;
        return;
      }
      let max = Math.ceil(total.value/paramsFilter.limit);
      if(paramsFilter.currentPage>max){
        paramsFilter.currentPage = max;
        return;
      }
      getTable();
    }

    getTable();

    return {
      tableLoading,
      paramsFilter,
      state,
      total,
      getTable,
      success,
      handlerFilterRequestClick,
      handlerFilterNotRequestClick,
      handlePageClick,
      handlerPageNumChange,
      handlerPageSizeChange,
      updateDisAble,
      addEditUserHandler,
      deleteUserHandler,
      showDialog,
      addDialogComp,
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.unify-iconfontxiaogantanhao {
  margin-left: 8px;
  font-weight: 600;

  &.red {
    color: red;
  }

  &.yellow {
    color: #fca506;
  }
}

.dbNavList {
  padding: 20px 0;
  background-color: #ffffff;
  border-radius: 20px 0 0 0;
}

.input-container {
  height: 38px;
  border: 1px solid #ccc;
  border-radius: 4px;

  .iconfont {
    font-size: 20px;
    font-weight: 600;
  }
  :deep(.el-input__wrapper) {
    box-shadow: none !important;
    background: transparent !important;

    &.is-focused {
      box-shadow: none !important;
    }

    &.is-hovering {
      box-shadow: none !important;
    }
  }
}
.my-button{
  width: 60px;
  height: 40px;
  background-color: #2054D6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 18px;
  cursor: pointer;
}
.jia{

  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}

.input-container:focus-within {
  border: 1px solid #409eff;
}

.button-container {
  height: 40px;
  width: 64px;
  text-align: center;
  line-height: 40px;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
  background: #2054D6;
  border-radius: 0px 2px 2px 0;
}



.filter-container {
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 14px;
}

.filter-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
}

.filter-tip {
  text-align: left;
  width: 56px;
  margin-bottom: 15px;
  margin-right: 10px;
  color: #000000;
}

.filter-item {
  padding: 3px 5px;
  margin-bottom: 15px;
  margin-right: 20px;
  white-space: nowrap;
  user-select: none;
  color: #2B3348;
  cursor: pointer;

  &.checked {

    color: #ffffff;
    background-color: #2054D6;
    border-radius: 2px;
  }
}

.tag-img {
  display: inline-block;
  height: 17px;
  margin-left: 8px;
}

.config-text-button {
  font-size: 14px;
  color: #1239A6;
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 8px;
}

.edit-text-button {
  font-size: 14px;
  color: #3556fc;
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 12px;
}
.delete-text-button{
  font-size: 14px;
  color: red;
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
}

.my-table {
  border: 1px solid #DCE1F0;
  border-radius: 10px 10px 10px 10px;
}

.page-button-next {
  width: 72px;
  height: 40px;
  background: #EEF2FE;
  border-radius: 10px 10px 10px 10px;
  font-size: 16px;
  color: #6573A2;
  line-height: 40px;
  text-align: center;
  opacity: 1;
  border: 1px solid #DCE1F0;
  user-select: none;
  cursor: pointer;
  margin-left: 15px;
}

.page-button-pre {
  width: 72px;
  height: 40px;
  background: #EEF2FE;
  border-radius: 10px 10px 10px 10px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #6573A2;
  opacity: 1;
  border: 1px solid #DCE1F0;
  user-select: none;
  cursor: pointer;
  margin-right: 15px;
}

:deep(.el-table__header) {


  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  height: 64px;

  .el-table__cell {
    background: #EEF2FE !important;
    color: #6573A2;
  }
}

:deep(.el-table__row) {
  height: 64px;

  .el-table__cell {
    color: #6573A2;
  }
}

:deep(.el-table__row--striped) {

  .el-table__cell {
    background: #F8F9FD !important;
  }
}

:deep(.el-pager) {
  .number {
    font-size: 14px;
    color: #6573A2;
    background: none !important;
  }

  .more {
    background: none !important;
  }

  .is-active {
    width: 40px;
    height: 40px;
    background: #2054D6 !important;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    border: 1px solid #DCE1F0;
  }
}

</style>
