<template>
  <div class="ResourcePromotion">
    <div class="menu flex" style="height: 70px;position: fixed;top: 0">
      <div class="item" :class="actStr==repairPath('silentExposure')?'act':''"
           @click="routerChange('silentExposure')">
        <i class="iconfont unify-a-sunrise-arrow-up1"/>沉寂曝光
      </div>
      <div class="item" :class="actStr==repairPath('resourcePromotionManagement')?'act':''"
           @click="routerChange('resourcePromotionManagement')">
        <i class="iconfont unify-folder-shared"/>推广管理
      </div>
      <div class="item" :class="actStr==repairPath('promotionAmbassador')?'act':''"
           @click="routerChange('promotionAmbassador')">
        <i class="iconfont unify-teacher-whiteboard"/>推广大使
      </div>
    </div>
    <div class="view-container">
      <router-view/>
    </div>
  </div>
</template>

<script>
import {ref, reactive, watch, onBeforeUnmount} from "vue";
import {useRoute} from "vue-router";
import router from "@/router"

const baseRoute = '/resourcePromotion/'
export default {
  setup(props, {attrs, slots, emit, expose}) {
    const route = useRoute();
    const actStr = ref(route.fullPath);
    const currentBaseRoute = baseRoute;
    const repairPath = (path) => {
      return baseRoute + path;
    }
    const routerChange = function (path) {
      router.push(baseRoute + path);
    }
    const watchRoute = () => {
      const stop = watch(
          () => route.fullPath,
          (to, from) => {
            // 处理路由变化的逻辑
            console.log('路由变化：', to, from);
            actStr.value =to;
          }
      );

      onBeforeUnmount(() => {
        stop();
      });
    };

    watchRoute();
    return {
      actStr,
      routerChange,
      currentBaseRoute,
      repairPath
    }

  }
}
</script>

<style scoped lang="scss">
.ResourcePromotion {
  .menu {
    margin-left: 20px;

    .item {
      padding: 24px 0;
      margin-right: 80px;
      cursor: pointer;
      font-size: 15px;
      color: #424655;
      transition: 0.3s ease;
      display: flex;
      align-items: center;

      &:hover {
        color: #2054D6;
      }

      .iconfont {
        font-size: 22px;
        margin-right: 4px;
      }

      &.act {
        color: #2054D6;
        font-weight: 600;
      }
    }
  }
}
.view-container{
  background: #F7F8FA !important;
  overflow: scroll;
}

</style>
