// 全局通用组件
// 省略
import ellipsis from "@/components/basic/ellipsis.vue";
// 通用弹窗
import customDialog from "./basic/customDialog.vue";
// 通用表格
import customTable from "./basic/customTable.vue";
// 通用分页
import customPagination from "./basic/customPagination.vue";

import { createApp } from "vue";
const app = createApp({});
const allGloabComponent = {
  ellipsis,
  customDialog,
  customTable,
  customPagination
};

export default {
  install(app) {
    Object.keys(allGloabComponent).forEach((key) => {
      app.component(key, allGloabComponent[key]);
    });
  },
};
