<template>
  <div class="recommend-key-word-list flex a-c">
    <div class="swiper-view">
      <div class="swiper-container" :style="`left:-${ currentIndex * swiperViewWidth}px`">
        <div class="key-word-item" @click="clickKeyWord(item)" :class="item.name==currentKeyWord?'checked':''" v-for="item in keywordList">{{ item.name }}</div>
      </div>

    </div>
    <div class="scroll-arrow flex a-c">
      <i @click="prevHandler" style="margin-right: 5px" class="iconfont unify-previous1"
         :class="isHasPrev?'isHas cursor-pointer':''"></i>
      <i @click="nextHandler" class="iconfont unify-next1"
         :class="isHasNext?'isHas cursor-pointer':''"></i>
    </div>

  </div>
</template>

<script>

export default {
  name: "recommend-key-word-list",
  props: {
    keywordList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      //当前显示的关键词列表的对应页数
      currentIndex: 0,
      //是否还有下一页
      isHasNext: false,
      //是否还有上一页
      isHasPrev: false,
      //当前选中得关键词
      currentKeyWord: '',
      swiperViewWidth: 0,
      swiperContainerWidth: 0,
    }
  },
  components: {
  },
  created() {

  },
  mounted() {

  },
  methods: {
    //点击关键词
    clickKeyWord(item) {
      this.currentKeyWord = item.name;
      this.$emit('clickKeyWord', {...item});
    },
    //根据当前页数，重新计算currentList
    setCurrentList() {
      //获取类喂swiper-view元素的宽度
      this.swiperViewWidth = this.$el.querySelector('.swiper-view').offsetWidth;
      //获取类喂swiper-container元素的宽度
      this.swiperContainerWidth = this.$el.querySelector('.swiper-container').offsetWidth;
      this.judgeIsHas();
    },
    //判断当前选中哪一项
    setCurrentRecommendTypeHandler(id) {

    },
    //判断是否还有上一页，下一页
    judgeIsHas() {
      if(this.currentIndex === 0){
        this.isHasPrev = false;
      }else{
        this.isHasPrev = true;
      }
      if(this.swiperViewWidth + (this.currentIndex * this.swiperViewWidth) >= this.swiperContainerWidth){
        this.isHasNext = false;
      }else{
        this.isHasNext = true;
      }

    },
    //上一页
    prevHandler() {
      if(this.isHasPrev){
        this.currentIndex -= 1;
        this.setCurrentList();
      }
    },
    //下一页
    nextHandler() {
      if(this.isHasNext){
        this.currentIndex += 1;
        this.setCurrentList();
      }
    },

  }
}
</script>

<style scoped lang="scss">
//.swiper {
//  width: 1000px;
//  height: 100px;
//}

.recommend-key-word-list {
  padding: 10px 15px;
  border-radius: 2px;
  box-sizing: border-box;
  background: linear-gradient(270deg, rgba(86, 109, 246, 0.09) 0%, rgba(86, 109, 246, 0) 66%, rgba(86, 109, 246, 0) 100%);
}

.swiper-view{
  width:calc(100% - 120px);
  position: relative;
  height: 23px;
  overflow: hidden;
}
.swiper-container{
  position: absolute;
  white-space: nowrap;
  //left: 0;
  top: 0;
  transition: ease 200ms;
}

.key-word-item {
  display: inline-block;
  font-size: 14px;
  color: #515151;
  margin-right: 30px;
  cursor: pointer;
  height: 23px;

  &.comprehensive {
    margin-right: 20px;
  }

  &.checked {
    color: #1239A6;
    font-weight: 600;
  }
}

.scroll-arrow {
  margin-left: auto;

  .iconfont {
    font-size: 20px;
    color: #E0E0E0;

    &.isHas {
      cursor: pointer;
      color: #D0D6FF;
    }
  }
}
</style>