<template>
  <!-- 文字超出显示...并悬浮显示全部文字 -->
  <div ref="ellDom" class="ell-wrap" @click="click">
    <div class="nowrap" v-if="!overFlag">{{ data }}</div>
    <el-tooltip
      class="name-tip"
      effect="dark"
      :content="data"
      :placement="placement"
      v-else
    >
      <div class="ell">{{ data }}</div>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: "ellipsis",
  props: {
    data: {
      default: "",
    },
    placement: {
      default: "top-start",
    },
  },
  data() {
    return {
      overFlag: false,
    };
  },
  watch:{
    data:{
        handler(newval){
            this.calcOverFlag()
        },
        deep:true,
        immediate:true
    }
  },
  created() {
    // this.calcOverFlag();
  },
  methods: {
    calcOverFlag() {
      this.$nextTick(() => {
        this.overFlag =
          this.$refs.ellDom.scrollWidth > this.$refs.ellDom.clientWidth;
      });
    },
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.ell-wrap {
  max-width: 100%;
  display: inline-block;
  .nowrap {
    white-space: nowrap;
  }
  // 字符串超出显示...
  .ell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
