<template>
  <div class="dbNavList">
    <div class="input-container flex a-c">
      <el-input v-model="paramsFilter.search" clearable @clear="getTable" placeholder="请输入数据库名称"/>
      <div class="button-container" @click="getTable"><i class="iconfont unify-sousuo"></i></div>
    </div>
    <div class="filter-container">

      <div class="alphabet-row filter-row">
        <div class="filter-tip">首字母：</div>
        <div class="filter-item" :class="'' == paramsFilter.letter ? 'checked' : ''"
             @click="handlerFilterRequestClick('letter','')">全部
        </div>
        <div class="filter-item" :class=" item == paramsFilter.letter ? 'checked' : ''"
             @click="handlerFilterRequestClick('letter',item)"
             v-for="item in state.filterListObj.alphabetList" :key="item">{{ item }}
        </div>
      </div>
      <template v-if="state.filterListObj.customDatabaseTypeFlag">
        <div class="resource-row filter-row">
          <div class="filter-tip">类型：</div>
          <div class="filter-item" :class="'' == paramsFilter.documentTypeStr ? 'checked' : ''"
               @click="handlerFilterRequestClick('documentTypeStr','')">全部
          </div>
          <div class="filter-item" :class="item.value == paramsFilter.documentTypeStr ? 'checked' : ''"
               @click="handlerFilterRequestClick('documentTypeStr',item.value)"
               v-for="item in state.filterListObj.documentTypeListStr" :key="item.value">{{ item.name }}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="resource-row filter-row">
          <div class="filter-tip">类型：</div>
          <div class="filter-item" :class="'' == paramsFilter.documentType ? 'checked' : ''"
               @click="handlerFilterRequestClick('documentType','')">全部
          </div>
          <div class="filter-item" :class="item.value == paramsFilter.documentType ? 'checked' : ''"
               @click="handlerFilterRequestClick('documentType',item.value)"
               v-for="item in state.filterListObj.documentTypeList" :key="item.value">{{ item.name }}
          </div>
        </div>
      </template>

    </div>
    <div>
      <el-table :data="state.tableData"
                max-height="calc(100vh - 430px)"
                stripe style="width: 100%" class="my-table" v-loading="tableLoading">
        <el-table-column prop="date" label="数据库" width="320px">
          <template #default="scope">
            <div class="flex a-c" style="flex-wrap: wrap;">
              <span>
                <span @click="configDetailClick(scope.$index, scope.row)" class="row-name">{{ scope.row.dbName }}</span>

                              <img class="tag-img" v-if="scope.row.configFlag==0" :src="iconEnum[1].imageUrl"/>
                <!--              <span v-if="scope.row.configFlag==0 && scope.row.topFlag==1"></span>-->
              <img class="tag-img" v-if="scope.row.topFlag==1" :src="iconEnum[0].imageUrl"/>
                              <el-tooltip
                                  class="box-item"
                                  effect="light"
                                  content="数据库订购状态异常，请核实，关闭显示后该数据库不再出现"
                                  placement="top-start"
                              >
                <i v-if="scope.row.configureOrderExceptionFlag==1" class="iconfont unify-iconfontxiaogantanhao red"></i>
              </el-tooltip>
              <el-tooltip
                  class="box-item"
                  effect="light"
                  content="数据库订购时间设置异常"
                  placement="top-start"
              >
                <i v-if="scope.row.orderTimeExceptionFlag==1" class="iconfont unify-iconfontxiaogantanhao yellow"></i>
              </el-tooltip>
              </span>


            </div>
          </template>
        </el-table-column>
        <el-table-column prop="languageName" label="中外类型" width="180" align="center">
          <template #header>
            <unifySelect placeholder="中外类型"
                         @handlerSelectEvent="(value)=>handlerFilterNotRequestClick('language',value)"
                         :options="state.filterListObj.languageTypeList"/>
          </template>
        </el-table-column>
        <el-table-column prop="propertyNameStr" label="类型" align="center">
          <template #default="scope">
            {{scope.row.propertyNameStr || '-'}}
          </template>
        </el-table-column>
        <el-table-column prop="totalFlagStr" label="层级" align="center" width="180">
          <template #header>
            <unifySelect placeholder="层级"
                         @handlerSelectEvent="(value)=>handlerFilterNotRequestClick('totalFlag',value)"
                         :options="state.filterListObj.levelList"/>
          </template>
        </el-table-column>
        <el-table-column prop="contractEndTime" label="到期日期" align="center">
          <template #default="scope">
            {{ scope.row.contractEndTime || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="orderStateName" label="订购状态" align="center" width="180">
          <template #header>
            <unifySelect placeholder="订购状态"
                         @handlerSelectEvent="(value)=>handlerFilterNotRequestClick('orderState',value)"
                         :options="state.filterListObj.orderStateList"/>
          </template>
        </el-table-column>
        <el-table-column prop="openFlagStr" label="展示状态" align="center" width="180">
          <template #header>
            <unifySelect placeholder="展示状态"
                         @handlerSelectEvent="(value)=>handlerFilterNotRequestClick('openFlag',value)"
                         :options="state.filterListObj.openTypeList"/>
          </template>
          <template #default="scope">
            {{ scope.row.openFlagStr || '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" align="center" fixed="right" width="220px">
          <template #default="scope">
            <span class="config-text-button" @click="configDetailClick(scope.$index, scope.row)">配置详情</span>
            <span class="edit-text-button" v-if="scope.row.topFlag==1" @click="cancelFirst(scope.$index, scope.row)">取消置顶</span>
            <span class="edit-text-button" v-if="scope.row.topFlag!=1 && scope.row.configFlag!=0"
                  @click="cancelFirst(scope.$index, scope.row)">置顶</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex a-c" style="justify-content: flex-end;margin-top: 20px">
        <span style="margin-right: 12px;color: #6573A2;font-size: 16px">总数：{{ total }}</span>
        <div class="page-button-pre" @click="handlePageClick(false)">上一页</div>
        <el-pagination
            background
            layout="pager,sizes"
            v-model:page-size="paramsFilter.pageSize"
            :page-sizes="[5,10,20]"
            :current-page="paramsFilter.pageNum"
            @current-change="handlerPageNumChange"
            @size-change="handlerPageSizeChange"
            :total="total"/>
        <div class="page-button-next" @click="handlePageClick(true)">下一页</div>
      </div>
    </div>

  </div>
</template>

<script>
import {findDatabaseManageList, findManagerQueryParamList, setTopFlag} from "@/js/network/main"
import {reactive, toRefs, ref, isReactive} from "vue";
import unifySelect from "@/components/defineElementPlus/unifySelect"
import router from "@/router"
import {iconEnums} from "../../until"
import {MessageBox} from "@/js/utils/commonJs";


export default {
  components: {
    unifySelect,
  },
  setup(props, context) {
    const iconEnum = iconEnums;
    let tableLoading = ref(true);
    let paramsFilter = reactive({ // 筛选参数
      search: "",//搜索关键词
      letter: "",//搜索字母
      documentType: "",//资源类型
      documentTypeStr: "",//资源类型
      pageSize: 10,//当前页大小
      pageNum: 1,//当前页
      language: "",//中外文类型
      totalFlag: "",//层级
      orderState: "",//订购状态
      openFlag: "",//展示状态 1 开启  0关闭
    });
    let state = reactive({
      tableData: [],
      filterListObj: {},// 表头筛选枚举
    })
    let total = ref(0);

    const getParamsEnums = function () {
      findManagerQueryParamList().then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        if (!res || !res.data) {
          return;
        }
        state.filterListObj = {...res.data};
        state.filterListObj.alphabetList = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];//字母表
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error, "catchError");
      })
    }
    //初始化操作
    const getTable = function () {
      tableLoading.value = true;
      findDatabaseManageList(paramsFilter).then(res => {
        if (!res || !res.data) {
          tableLoading.value = false;
          return;
        }
        state.tableData = res.data;
        total.value = parseInt(res.count);
        tableLoading.value = false;
      }).catch(error => {
        if (error.message == "Request Canceled!") {
          return
        }
        tableLoading.value = false;
      });

    }

    //筛选并请求
    const handlerFilterRequestClick = function (key, value) {
      paramsFilter[key] = value;
      paramsFilter.pageNum = 1;
      getTable();
    }

    //表格头组件自定义事件
    const handlerFilterNotRequestClick = function (key, value) {
      paramsFilter[key] = value;
      getTable();
    }

    const handlerPageNumChange = function (num) {
      paramsFilter.pageNum = num;
      getTable();
    }
    const handlerPageSizeChange = function (num) {
      paramsFilter.pageSize = num;
      paramsFilter.pageNum = 1;
      getTable();
    }

    const configDetailClick = function (index, row) {
      router.push({
        path: "/dbConfig/detailSetting",
        query: {
          id: row.id,
          dId: row.dId
        }
      });
    }
    const cancelFirst = function (index, row) {
      setTopFlag({id: row.id}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        MessageBox.success("操作成功！");
        getTable();
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error, "catchError");
      });
    }
    const handlePageClick = function (flag) {
      flag ? paramsFilter.pageNum++ : paramsFilter.pageNum--;
      if(paramsFilter.pageNum<1){
        paramsFilter.pageNum = 1;
        return;
      }
      let max = Math.ceil(total.value/paramsFilter.pageSize);
      if(paramsFilter.pageNum>max){
        paramsFilter.pageNum = max;
        return;
      }
      getTable();
    }

    //setup 不需要create生命周期，此方法就相当于是create生命周期
    //获取参数
    getParamsEnums();
    getTable();

    return {
      tableLoading,
      iconEnum,
      paramsFilter,
      state,
      total,
      getTable,
      handlerFilterRequestClick,
      handlerFilterNotRequestClick,
      handlePageClick,
      handlerPageNumChange,
      handlerPageSizeChange,
      configDetailClick,
      cancelFirst
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.unify-iconfontxiaogantanhao {
  margin-left: 8px;
  font-weight: 600;

  &.red {
    color: red;
  }

  &.yellow {
    color: #fca506;
  }
}

.dbNavList {
  padding-top: 20px;
  background-color: #ffffff;
  border-radius: 20px 0 0 0;
}
.row-name{
  cursor: pointer
}
.row-name:hover{
  text-decoration: underline;
}

.input-container {
  height: 38px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;

  .iconfont {
    font-size: 20px;
    font-weight: 600;
  }
}

.input-container:focus-within {
  border: 1px solid #409eff;
}

.button-container {
  height: 40px;
  width: 64px;
  text-align: center;
  line-height: 40px;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  user-select: none;
  background: #2054D6;
  border-radius: 0px 2px 2px 0;
}

:deep(.el-input__wrapper) {
  box-shadow: none !important;
  background: transparent !important;

  &.is-focused {
    box-shadow: none !important;
  }

  &.is-hovering {
    box-shadow: none !important;
  }
}

.filter-container {
  margin-bottom: 20px;
  box-sizing: border-box;
  font-size: 14px;
}

.filter-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
}

.filter-tip {
  text-align: left;
  width: 56px;
  margin-bottom: 15px;
  margin-right: 10px;
  color: #000000;
}

.filter-item {
  padding: 4px 5px;
  margin-bottom: 15px;
  margin-right: 20px;
  white-space: nowrap;
  user-select: none;
  color: #2B3348;
  line-height: 14px;
  cursor: pointer;

  &.checked {

    color: #ffffff;
    background-color: #2054D6;
    border-radius: 2px;
  }
}

.tag-img {
  display: inline-block;
  height: 17px;
  margin-left: 8px;
  position: relative;
  top: 2px;
}

.config-text-button {
  font-size: 14px;
  color: #1239A6;
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 8px;
}

.edit-text-button {
  font-size: 14px;
  color: #FC3535;
  user-select: none;
  cursor: pointer;
  text-decoration: underline;
}

.my-table {
  border: 1px solid #DCE1F0;
  border-radius: 10px 10px 10px 10px;
}

.page-button-next {
  width: 72px;
  height: 40px;
  background: #EEF2FE;
  border-radius: 10px 10px 10px 10px;
  font-size: 16px;
  color: #6573A2;
  line-height: 40px;
  text-align: center;
  opacity: 1;
  border: 1px solid #DCE1F0;
  user-select: none;
  cursor: pointer;
  margin-left: 15px;
}

.page-button-pre {
  width: 72px;
  height: 40px;
  background: #EEF2FE;
  border-radius: 10px 10px 10px 10px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  color: #6573A2;
  opacity: 1;
  border: 1px solid #DCE1F0;
  user-select: none;
  cursor: pointer;
  margin-right: 15px;
}

:deep(.el-table__header) {


  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  height: 64px;

  .el-table__cell {
    background: #EEF2FE !important;
    color: #6573A2;
  }
}

:deep(.el-table__row) {
  height: 64px;

  .el-table__cell {
    color: #6573A2;
  }
}

:deep(.el-table__row--striped) {

  .el-table__cell {
    background: #F8F9FD !important;
  }
}

:deep(.el-pager) {
  .number {
    font-size: 14px;
    color: #6573A2;
    background: none !important;
  }

  .more {
    background: none !important;
  }

  .is-active {
    width: 40px;
    height: 40px;
    background: #2054D6 !important;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    border: 1px solid #DCE1F0;
  }
}

</style>
