<template>
  <el-dialog
      v-model="dialogVisible"
      title="平台访问账号"
      width="600px" :before-close="handleClose"
  >
    <div class="main-container">
      访问资源推广协作平台 <br/><br/>
      推广人员：{{ adminAccount.name }}<br/>
      账号：{{ adminAccount.accountName }}<br/>
      账号密码：{{ adminAccount.password }}<br/><br/><br/>
      点击链接免登录访问<br/><br/>
      <span class="link-url" @click="linkToUrl">https://promotion.resourcemap.com.cn/home?code={{adminAccount.noLoginCode}}</span><br/><br/><br/>
      点击复制按钮复制账号信息到剪切板
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="copy">
          复制
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {reactive, ref} from 'vue'
import {findOneById} from "@/js/network/main/index"
import {MessageBox} from "@/js/utils/commonJs";
import Clipboard from 'clipboard';

export default {
  setup(props, {attrs, slots, emit, expose}) {
    const personId = ref("");
    const adminAccount = ref({});
    const adminLoading = ref(false)
    const dialogVisible = ref(false)

    const handleClose = () => {
      personId.value = "";
      dialogVisible.value = false;
    }
    const init = (row) => {
      personId.value = row.id;
      dialogVisible.value = true;
      findAccount();

    }
    const findAccount = () => {
      adminLoading.value = true;
      findOneById({id: personId.value}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        adminLoading.value = false;
        adminAccount.value = {...res.data};
        // MessageBox.success('添加成功!');
        // emit("success");
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error, "catchError");
      });
    }
    const linkToUrl = () => {
      window.open('https://promotion.resourcemap.com.cn/home?code='+adminAccount.value.noLoginCode,"_blank");
    }
    const copy = () => {
      let keys = Object.keys(adminAccount.value)
      const clipboard = new Clipboard('.btn', {
        text: () => {
          let str = "";
          keys.forEach(r => {
            str += r;
            str += "：";
            str += adminAccount.value[r];
            str += "\n";
          })
          return str
        }
      });

      clipboard.on('success', () => {
        MessageBox.success('链接已成功复制到剪贴板');
        clipboard.destroy();
      });

      clipboard.on('error', () => {
        MessageBox.error('复制失败');
        clipboard.destroy();
      });

      // 触发复制动作
      clipboard.onClick(event);
    }

    return {
      personId,
      adminAccount,
      dialogVisible,
      linkToUrl,
      handleClose,
      init,
      findAccount,
      copy,
      ...props
    }

  }
}


</script>
<style scoped lang="scss">
.dialog-footer button:first-child {
  margin-right: 10px;
}

.main-container {

}
.link-url{
  color: #1a4ddb;
  text-decoration: underline;
  cursor: pointer;
}

.row {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 12px;

  .row-tip {
    font-size: 14px;
    color: #333333;
    font-weight: 600;
    margin-right: 12px;
    position: relative;

    .unify-bitian {
      color: red;
      position: absolute;
      left: -15px;
      top: 3px;
    }
  }
}
</style>
