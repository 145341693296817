<template>
  <div class="ChildRelate">
    <div class="tip-img-container">
      <el-table :data="dataList" border
                style="width: 100%" class="my-table" id="childRelate"
                :row-key="(row)=>{return row.id}"
                max-height="calc(100vh - 300px)" v-loading="tableLoading">
        <el-table-column
            label="序号"
            width="60"
            align="center"
        >
          <template #default="scope">
            <i v-if="isEdit" class="iconfont unify-keyidongmoren"></i>
            <span v-if="!isEdit">{{scope.$index+1}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="数据库名称">
          <template #default="scope">
            <div class="flex" style="flex-wrap: wrap">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-affix position="bottom" :offset="20">
      <div class="button-container flex a-c j-c">
        <el-button color="#2054D6" @click="handleEdit">{{isEdit?'取消':'排序'}}</el-button>
        <el-button color="#626aef" @click="submit">保存</el-button>
      </div>
    </el-affix>

  </div>
</template>

<script>
import { Sortable } from "sortablejs";
import {ref, reactive, onMounted, markRaw} from "vue";
import {saveSort, findChildListByUnifyVdatabaseDetailsId} from "@/js/network/main";
import {MessageBox} from "@/js/utils/commonJs";
import {useRoute} from "vue-router";
export default {
  setup(props, {attrs,slots,emit,expose}) {
    const route = useRoute();
    const options = reactive({});
    const dataList = reactive([]);
    const tableLoading = false;
    const isEdit = ref(false);
    let tableContainer = null;
    const handleEdit = function(){
      isEdit.value = !isEdit.value;
      if(isEdit.value){
        const tbody = document.querySelector("#childRelate tbody");
        tableContainer = Sortable.create(tbody, {
          // 结束拖拽后的回调函数
          onEnd({newIndex, oldIndex}) {
            let currentRow = dataList.splice(oldIndex, 1)[0];
            dataList.splice(newIndex, 0, currentRow);
          },
          animation: 150,
        })
      }else{
        tableContainer.destroy();
      }
    }
    const submit = function(){
      if(dataList.length==0){
        return
      }
      let {id} = route.query
      let sortList = dataList.map((item,index)=>{
        item.sort = index+1 ;
        return {id:item.id,sort:item.sort};
      })
      saveSort({unifyVdatabaseDetailsId: id,sortList:sortList}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        MessageBox.success("保存成功!");
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error, "catchError");
      });

    }
    const init = function () {
      let {id} = route.query

      findChildListByUnifyVdatabaseDetailsId({unifyVdatabaseDetailsId: id}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        if (!res || !res.data) {
          return;
        }
        res.data.sort((a,b) => a.sort - b.sort);
        res.data.forEach(r=>{
          dataList.push(r);
        });

      }).catch(error => {
        //MessageBox.error(error);
        console.log(error, "catchError");
      });

    }
    init();
    onMounted(()=>{

    })
    return {
      isEdit,
      handleEdit,
      tableContainer,
      dataList,
      options,
      tableLoading,
      submit
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tip-img-container{
  min-height: 400px;
  margin-bottom: 25px;
}
.unify-keyidongmoren{
  cursor: pointer;
}

</style>
