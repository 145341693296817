<template>
  <el-dialog
      v-model="state.dialogVisible"
      title="配置开启时间"
      width="30%"
      :before-close="handleClose"
  >
    <div class="main-container">
      <el-date-picker
          style="width: 220px;margin-bottom: 20px"
          v-model="state.startTime"
          type="date"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          placeholder="输入开始时间"
      />
      <br/>
      <el-date-picker
          v-model="state.endTime"
          type="date"
          format="YYYY-MM-DD"
          value-format="YYYY-MM-DD"
          placeholder="输入结束时间"
      />
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="state.dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmHandler">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {reactive} from 'vue'
export default {
  props:{
    editTimeHandler:{
      type:Function,
    }
  },
  setup(props, {attrs, slots, emit, expose}) {
    const state = reactive({
      dialogVisible:false,
      startTime:"",
      endTime:""
    })

    const handleClose = () => {
      state.dialogVisible = false;
      state.startTime = "";
      state.endTime = "";
    }
    const init = (start,end)=>{
      state.startTime = start;
      state.endTime = end;
      state.dialogVisible = true;
    }
    const confirmHandler = ()=>{
      state.dialogVisible = false;
      props.editTimeHandler({...state});
    }
    return {
      state,
      handleClose,
      init,
      confirmHandler,
      ...props
    }

  }
}


</script>
<style scoped>
.dialog-footer button:first-child {
  margin-right: 10px;
}
.color-list{
    margin-top: 20px;
}
.color-item{
  width: 28px;
  height: 28px;
  margin-right: 10px;
  cursor: pointer;
}
</style>
