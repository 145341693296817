<template>
  <el-dialog
      v-model="dialogVisible"
      title="添加推广人员"
      width="400px"
      :before-close="handleClose"
  >
    <div class="main-container">
      <div class="row  flex a-c">
        <div class="row-tip"><i class="iconfont unify-bitian"></i>名称:</div>
        <el-input style="width: 220px" v-model="userName" maxlength="20" placeholder="人员名称"/>
      </div>

    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmHandler">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {ref} from 'vue'
import {addPromotionUser} from "@/js/network/main/index"
import {MessageBox} from "@/js/utils/commonJs";

export default {
  setup(props, {attrs, slots, emit, expose}) {
    const userName = ref("");
    const dialogVisible  = ref(false)

    const handleClose = () => {
      userName.value = "";
      dialogVisible.value = false;
    }
    const init = () => {
      dialogVisible.value = true;
    }
    const confirmHandler = () => {
      addPromotionUser({name:userName.value}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        handleClose();
        MessageBox.success('添加成功!');
        emit("success");
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error,"catchError");
      });
    }
    return {
      userName,
      dialogVisible,
      handleClose,
      init,
      confirmHandler,
      ...props
    }

  }
}


</script>
<style scoped lang="scss">
.dialog-footer button:first-child {
  margin-right: 10px;
}
.main-container{

}
.row{
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 12px;
  .row-tip{
    font-size: 14px;
    color: #333333;
    font-weight: 600;
    margin-right: 12px;
    position: relative;
    .unify-bitian{
      color: red;
      position: absolute;
      left: -15px;
      top: 3px;
    }
  }
}
</style>
