import user from './modules/user'
import permission from './modules/permission';
import getters from './getters'

// vuex持久化插件
import SecureLS from "secure-ls";
import createPersistedState from "vuex-persistedstate"

const ls = new SecureLS({
    encodingType: "aes",
    isCompression: false,
    encryptionSecret: "secret-store"
});


import { createStore } from 'vuex'

const store = createStore({
    plugins: [
        createPersistedState({
            key: "kc_unify_store",
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            },
            paths: [
                "user.userData"
            ]
        })

        //"permission.navRoutes"
    ],
    modules: {
        user,
        permission
    },
    getters
})


export default store
