<template>
  <div class="xxx">
    adminManagement
  </div>
</template>

<script>

export default {
  setup(props, {attrs,slots,emit,expose}) {

    return {

    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">


</style>
