<template>
    <router-view></router-view>
</template>

<script>



export default {
  name: 'App',
  components: {

  },
  created() {

  },
  methods:{

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
