<template>
  <div class="logo">
    <div class="container" @click="goHome"/>
  </div>
</template>

<script setup>
import store from '@/store/index';
import router from "@/router"

function goHome(){
  const routeList = store.getters.getAddRoutes;

  router.push(routeList[0].path);
}
// import { }
// export default {
//   name: 'logo',
//   methods:{
//     goHome(){
      
//     }
//   }
// }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.logo {
  //height: 160px;
  .container {
    margin: 50px 15px;
    height: 60px;
    width: 120px;
    background-image: url("https://miniopreview.resourcemap.com.cn/ersmap-platform/system/unify/logo.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    cursor: pointer;
  }
}
</style>
