<template>
  <div class="ReadPromotionalReports flex">
    <div class="nav-block" @click="silentClick">
      <div class="nav-name">沉寂资源推广简报</div>
      <div class="nav-name-en">SILENT RESOURCE PROMOTION BRIEFING</div>
    </div>
    <div class="nav-block">
      <div class="nav-name">学科沉寂资源推广方案</div>
      <div class="nav-name-en">REPORT ON THE PROMOTION OF SILENT RESOURCES IN DISCIPLINES </div>
    </div>
    <pdfPrint ref="pdfPrintC"/>
  </div>
</template>

<script>
import pdfPrint from "./pdfPrint"
import {ref} from "vue"
export default {
  components:{
    pdfPrint
  },
  setup(props, {attrs, slots, emit, expose}) {
    const pdfPrintC = ref(null);
    const silentClick = function (){
      pdfPrintC.value.init();
    }

    return {
      pdfPrintC,
      silentClick
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ReadPromotionalReports {
  padding: 35px;
  box-sizing: border-box;
}

.nav-block {
  width: 360px;
  background: #F8F9FD;
  border-radius: 8px;
  padding: 18px 0px 18px 24px;
  box-sizing: border-box;
  border: 1px solid #DCE1F0;
  height: 600px;
  margin-right: 100px;
  user-select: none;
  cursor: pointer;
}
.nav-name{
  font-size: 18px;
  color: #333333;
  font-weight: 600;
  margin-top: 200px;
}
.nav-name-en{
  font-size: 14px;
  color: #7c7c7c;
  margin-top: 15px;
}

</style>
