/**
 * 获取随机字符串
 */
const randomStr = () => {
    return Math.random().toString(36).slice(-8)
}

/**
 * 生成uuid
 */
const uuid = () => {
    var s = [];
    var hexDigits = "0123456789abcdef";
    for (var i = 0; i < 32; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    // s[8] = s[13] = s[18] = s[23] = "-";
    var uuid = s.join("");
    return uuid;
}

/**
 * 判断字符串是否为空
 */
const isEmpty = (str) => {
    return str == undefined || str === "" || str == "null" || str == "undefined";
};

const isBlank = (str) => {
    var regu = "^[ ]+$";
    var re = new RegExp(regu);
    return isEmpty(str) || re.test(str);
};
const isNumberStr = (str) => {
    return /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g.test(str);
}
/**
 *  生成文件
 */
const generateFile = (filename, data, type) => {
    const a = document.createElement('a');
    const url = window.URL.createObjectURL(new Blob([data], { type: type }));
    a.href = url
    a.download = filename
    a.click()
    window.URL.revokeObjectURL(url)
};
/**
 * 格式化json
 */
const formatJsonForNotes = function (json, options) {
    var reg = null,
        formatted = '',
        pad = 0,
        PADDING = '  '; // （缩进）可以使用'\t'或不同数量的空格
    // 可选设置
    options = options || {};
    // 在 '{' or '[' follows ':'位置移除新行
    options.newlineAfterColonIfBeforeBraceOrBracket = (options.newlineAfterColonIfBeforeBraceOrBracket === true) ? true : false;
    // 在冒号后面加空格
    options.spaceAfterColon = (options.spaceAfterColon === false) ? false : true;
    // 开始格式化...
    if (typeof json !== 'string') {
        // 确保为JSON字符串
        json = JSON.stringify(json);
    } else {
        //已经是一个字符串，所以解析和重新字符串化以删除额外的空白
        json = JSON.parse(json);
        json = JSON.stringify(json);
    }
    // 在花括号前后添加换行
    reg = /([\{\}])/g;
    json = json.replace(reg, '\r\n$1\r\n');
    // 在方括号前后添加新行
    reg = /([\[\]])/g;
    json = json.replace(reg, '\r\n$1\r\n');
    // 在逗号后添加新行
    reg = /(\,)/g;
    json = json.replace(reg, '$1\r\n');
    // 删除多个换行
    reg = /(\r\n\r\n)/g;
    json = json.replace(reg, '\r\n');
    // 删除逗号前的换行
    reg = /\r\n\,/g;
    json = json.replace(reg, ',');
    // 可选格式...
    if (!options.newlineAfterColonIfBeforeBraceOrBracket) {
        reg = /\:\r\n\{/g;
        json = json.replace(reg, ':{');
        reg = /\:\r\n\[/g;
        json = json.replace(reg, ':[');
    }
    if (options.spaceAfterColon) {
        reg = /\:/g;
        json = json.replace(reg, ': ');
    }
    json.split('\r\n').forEach((x) => {
        var i = 0,
            indent = 0,
            padding = '';
        if (x.match(/\{$/) || x.match(/\[$/)) {
            indent = 1;
        } else if (x.match(/\}/) || x.match(/\]/)) {
            if (pad !== 0) {
                pad -= 1;
            }
        } else {
            indent = 0;
        }
        for (i = 0; i < pad; i++) {
            padding += PADDING;
        }
        formatted += padding + x + '\r\n';
        pad += indent;
    })

    return formatted;
};

/**
 * 格式化时间
 */
const formatDate = (date, fmt = "yyyy-MM-dd hh:mm:ss") => {
    if (!date) {
        return "";
    }
    if (date.getFullYear()) {
        if (date.getFullYear() == 1970) {
            return "-";
        }
    } else {
        return "-";
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    }
    let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + "";
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length === 1 ? str : padLeftZero(str)
            );
        }
    }
    return fmt;
};
function padLeftZero(str) {
    return ("00" + str).substr(str.length);
}

// 深拷贝对象
export function deepClone(obj) {
    const _toString = Object.prototype.toString

    // null, undefined, non-object, function
    if (!obj || typeof obj !== 'object') {
        return obj
    }

    // DOM Node
    if (obj.nodeType && 'cloneNode' in obj) {
        return obj.cloneNode(true)
    }

    // Date
    if (_toString.call(obj) === '[object Date]') {
        return new Date(obj.getTime())
    }

    // RegExp
    if (_toString.call(obj) === '[object RegExp]') {
        const flags = []
        if (obj.global) { flags.push('g') }
        if (obj.multiline) { flags.push('m') }
        if (obj.ignoreCase) { flags.push('i') }

        return new RegExp(obj.source, flags.join(''))
    }

    const result = Array.isArray(obj) ? [] : obj.constructor ? new obj.constructor() : {}

    for (const key in obj) {
        result[key] = deepClone(obj[key])
    }

    return result
}
export function isObjectObject(t) {
    return toStr(t) === '[object Object]'
}

//下载文件
const download = (res, name = "") => {
    const disposition = res.headers["content-disposition"];
    let fileName = decodeURIComponent(escape(disposition.split("fileName=")[1]));
    if (!isBlank(name)) {
        let clearFileName = getFileNameWithOutExt(name);
        fileName = clearFileName + "." + suffix(fileName);
    }
    let blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    if (window.navigator.msSaveOrOpenBlob) {
        // 兼容IE
        navigator.msSaveBlob(blob, fileName);
        return;
    }
    // 创建URL
    let url = URL.createObjectURL(blob);
    // 创建元素 and 下载文件   此方式文件的后缀名由前端控制  文件名 or 后缀名出错会导致文件无法正常预览
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute("download", fileName);
    link.click();
    // 释放内存
    URL.revokeObjectURL(url);
    link.remove();
};

// 获取文件名，排除掉扩展名
const getFileNameWithOutExt = (fileName) => {
    let index = fileName.lastIndexOf(".");
    if (index > 0) {
        let ret = fileName.substr(0, index);
        return ret;
    }
    return fileName;
};

// 获取文件后缀名
const suffix = (fileName) => {
    let suffix = fileName.split(".");
    suffix = suffix[suffix.length - 1];
    return suffix;
};

//浏览器下载文件
const downloadUrl = (url) => {
    // 创建元素 and 下载文件   此方式文件的后缀名由前端控制  文件名 or 后缀名出错会导致文件无法正常预览
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    // link.setAttribute("download", fileName);
    link.click();
    // 释放内存
    URL.revokeObjectURL(url);
    link.remove();
};

//将url转为组件名称
const convertUrlToName = (url) => {
    if (isBlank(url)) {
        return "";
    }
    if (url.indexOf("?") > -1) {
        url = url.substr(0, url.indexOf("?"))
    }
    let ret = "";
    let arr = url.split('/');
    arr.forEach(x => {
        if (!isBlank(x)) {
            ret += x.replace(x[0], x[0].toUpperCase())
        }
    })
    return ret;
}

const getMetaFromRoutes = (routes, name) => {
    if (!routes || isBlank(name)) {
        return undefined;
    }
    for (let i in routes) {
        if (routes[i].name == name) {
            return routes[i].meta
        }
        if (routes[i].children && routes[i].children.length > 0) {
            let ret = getMetaFromRoutes(routes[i].children, name);
            if (ret) {
                return ret;
            }
        }
    }
    return undefined;
}
//转为空数组
const findArray = (array) => {
    if (array instanceof Array) {
        return array;
    }
    return []
}

// 日期格式化
const parseTime = (time, pattern) => {
    if (arguments.length === 0 || !time) {
        return null
    }
    const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time)
        } else if (typeof time === 'string') {
            time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
    return time_str
}
// 表单重置
const resetForm = (_that, refName) => {
    if (_that?.$refs[refName]) {
        _that.$refs[refName].resetFields();
        _that.$refs[refName].clearValidate();
    }
}
/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
const debounce = (func, wait, immediate) => {
    let timeout, args, context, timestamp, result

    const later = function () {
        // 据上一次触发时间间隔
        const last = +new Date() - timestamp

        // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
        if (last < wait && last > 0) {
            timeout = setTimeout(later, wait - last)
        } else {
            timeout = null
            // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
            if (!immediate) {
                result = func.apply(context, args)
                if (!timeout) context = args = null
            }
        }
    }

    return function (...args) {
        context = this
        timestamp = +new Date()
        const callNow = immediate && !timeout
        // 如果延时不存在，重新设定延时
        if (!timeout) timeout = setTimeout(later, wait)
        if (callNow) {
            result = func.apply(context, args)
            context = args = null
        }

        return result
    }
}
//获取自定义字段的显示值
const getCustomFieldValue = (field = {}, value = "") => {
    if (isBlank(value)) {
        return "";
    }

    switch (field.module) {
        case "input":
        case "textarea":
            return value;
        case "select":
        case "cascader":
            //递归查询
            let ret = [];
            //这里可能是单选
            let options = JSON.parse(field.options);
            let values = JSON.parse(value);
            values.forEach(x => {
                ret.push(getNodeName(options, x, 'value').join("/"))
            })
            return ret.join(";\xa0\xa0\xa0");
    }
}
/**
 * 获取链表节点名称 
 * @param {*} options       key可以自己指定，但是子级必须是children
 * @param {*} targetKey 
 * @param {*} key 
 */
const getNodeName = (options = [], targetKey = "", key = "id") => {
    let ret = [];
    let traverse = (list, path, targetKey, key) => {
        if (list.length == 0) {
            return;
        }
        list.forEach((item) => {
            path.push(item.name);
            if (item[key] == targetKey) {
                ret = JSON.parse(JSON.stringify(path));
                return;
            }
            const children = Array.isArray(item.children) ? item.children : [];
            // 递归遍历子数组内容
            traverse(children, path, targetKey, key);
            // 利用回溯思想，当没有在当前叶树找到目的节点，依次删除存入到的path数组路径
            path.pop();
        })
    };
    traverse(options, [], targetKey, key);
    return ret;
}

/**
 * 字节转为mb
 * @param {*} size 字节长度 
 * @returns 
 */
const getFileSize = (limit) => {
    let size = "";
    try {
        limit = Number(limit)
        if (limit < 0.1 * 1024) {                            //小于0.1KB，则转化成B
            size = limit.toFixed(2) + "B"
        } else if (limit < 0.1 * 1024 * 1024) {            //小于0.1MB，则转化成KB
            size = (limit / 1024).toFixed(2) + "KB"
        } else if (limit < 0.1 * 1024 * 1024 * 1024) {        //小于0.1GB，则转化成MB
            size = (limit / (1024 * 1024)).toFixed(2) + "MB"
        } else {                                            //其他转化成GB
            size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB"
        }
    } catch (error) {
        size = "0B";
    }

    let sizeStr = size + "";                        //转成字符串
    let index = sizeStr.indexOf(".");                    //获取小数点处的索引
    let dou = sizeStr.substr(index + 1, 2)            //获取小数点后两位的值
    if (dou == "00") {                                //判断后两位是否为00，如果是则删除00                
        return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
    }
    return size;
}
//格式化排序值
const formatSort = ($event) => {
    $event.target.value = $event.target.value.replace(/[^0-9]/g, '')
    if (isBlank($event.target.value)) {
        $event.target.value = "";
    }
    $event.target.dispatchEvent(new Event('input'))
}
//格式化文件名称
const formatFileName = ($event) => {
    $event.target.value = $event.target.value.replace(/[\\\/:*?"<>|]/g, '')
    if (isBlank($event.target.value)) {
        $event.target.value = "";
    }
    $event.target.dispatchEvent(new Event('input'))
}

//数组求和
const sumArray = (array) => {
    let sum = 0;
    for (let i = 0; i < array.length; i++) {
        sum += Number(array[i]);
    }
    return sum;
}
//url编码
const encodeUrl = (url) => {
    if (isBlank(url)) {
        return url;
    }
    return encodeURIComponent(url)
}
//url解码
const decodeUrl = (url) => {
    if (isBlank(url)) {
        return url;
    }
    return decodeURIComponent(url)
}
//获取url中的参数
const getUrlParam = (url,name) => {
    if(isBlank(url)){
        return url;
    }
    var reg = new RegExp("(^|&|\\?)" + name + "=([^&]*)(&|$)");
    var data = url.substr(1).match(reg);
    return data != null ? decodeUrl(data[2]) : null;
}
const getOneDay = function () {
    //起止日期数组
    const startStop = new Array();

    //一天的毫秒数
    const millisecond = 1000 * 60 * 60 * 24;

    //获取当前时间
    const currentDate = new Date();


    //获得当前周的第一天
    const beforeDay = new Date(`${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()} 00:00:00`)

    startStop.push(beforeDay.getTime());

    startStop.push(currentDate.getTime());

    return startStop;

}
/**
 返回这一周的时间戳
 * **/

var getWeekStartAndEnd = function () {
    //起止日期数组
    var startStop = new Array();

    //一天的毫秒数
    var millisecond = 1000 * 60 * 60 * 24;

    //获取当前时间
    var currentDate = new Date();

    //获取当前时间的信息


    //返回date是一周中的某一天
    var week = currentDate.getDay();

    //减去的天数
    var minusDay = week != 0 ? week - 1 : 6;

    //获得当前周的第一天
    var currentWeekFirstDay = new Date(currentDate.getTime() - (millisecond * minusDay));
    let year = currentWeekFirstDay.getFullYear()
    let mon = currentWeekFirstDay.getMonth() + 1
    let day = currentWeekFirstDay.getDate()
    currentWeekFirstDay = new Date(`${year}-${mon}-${day} 00:00:00`)
    startStop.push(currentWeekFirstDay.getTime());

    startStop.push(currentDate.getTime());

    return startStop;

}
export default {
    randomStr,
    isEmpty,
    isBlank,
    generateFile,
    formatDate,
    formatJsonForNotes,
    deepClone,
    isObjectObject,
    download,
    getFileNameWithOutExt,
    suffix,
    downloadUrl,
    convertUrlToName,
    getMetaFromRoutes,
    findArray,
    parseTime,
    resetForm,
    isNumberStr,
    debounce,
    getCustomFieldValue,
    getNodeName,
    uuid,
    getFileSize,
    formatSort,
    formatFileName,
    sumArray,
    encodeUrl,
    decodeUrl,
    getUrlParam,
    getOneDay,
    getWeekStartAndEnd
}