<template>
  <div class="baseInfo">
    <div class="grid-container">
      <div class="main-row flex a-c">
        <div class="row-tip" style="margin-right: 20px">
          置顶状态：
        </div>
        <div class="row-content flex a-c" style="padding-right: 50px">
          <span class="content-text">{{ state.topFlag == 1 ? "已置顶" : "未置顶" }}</span>
          <span class="font-button" @click="cancelTopHandler">{{ state.topFlag ? '关闭' : '开启' }}</span>
        </div>

      </div>
      <div class="main-row flex a-c">
        <div class="row-tip" style="margin-right: 20px">
          文献类型：
        </div>
        <div class="row-content" style="padding-right: 50px" v-if="state.customDatabaseTypeFlag">
          <el-text class="mx-1">{{state.customDatabaseTypeStr || '-'}}</el-text>
        </div>
        <div class="row-content" style="padding-right: 50px" v-else>
          <el-select v-model="state.propertyIdList" filterable multiple collapse-tags
                     clearable placeholder="请选择" style="width: 250px">
            <el-option
                v-for="item in stateOptions.documentTypeList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
            />
          </el-select>
        </div>

      </div>
    </div>

    <div class="grid-container">

      <div class="main-row flex a-c">
        <div class="row-tip" style="margin-right: 20px">
          开启NEW：
        </div>
        <div class="row-content flex a-c" style="padding-right: 50px">
          <span class="content-text" v-if="state.openNewStartTimeStr && state.openNewStartTimeStr ">
            {{ state.openNewStartTimeStr + "~" + state.openNewEndTimeStr }}
          </span>
          <span v-else class="content-text">待配置</span>
          <span class="font-button" @click="editTimeHandlerClick">{{ state.openNewFlag ? '关闭' : '开启' }}</span>
        </div>

      </div>

      <div class="main-row flex a-c">
        <div class="row-tip" style="margin-right: 20px">
          文献层次：
        </div>
        <div class="row-content" style="padding-right: 50px">
          <el-select v-model="state.literatureGrade" filterable clearable placeholder="请选择" style="width: 250px">
            <el-option
                v-for="item in stateOptions.literatureGradeModelList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
            />
          </el-select>
        </div>

      </div>

    </div>
    <div class="grid-container">
      <div class="main-row flex a-c">
        <div class="row-tip" style="margin-right: 20px">
          责任馆员：
        </div>
        <div class="row-content" style="padding-right: 50px">
          <el-select v-model="state.librarianId" filterable clearable placeholder="请选择" style="width: 250px">
            <el-option
                v-for="item in stateOptions.librarianList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
            />
          </el-select>
        </div>

      </div>
      <div class="main-row flex a-c">
        <div class="row-tip" style="margin-right: 20px">
          语种：
        </div>
        <div class="row-content" style="padding-right: 50px">
          <el-select v-model="state.languageType" filterable clearable placeholder="请选择" style="width: 250px">
            <el-option
                v-for="item in stateOptions.languageList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </div>

      </div>
    </div>
    <div class="grid-container">
      <div class="main-row flex a-c">
        <div class="row-tip" style="margin-right: 20px">
          教育部学科：
        </div>
        <div class="row-content" style="padding-right: 50px">
<!--          <el-select v-model="state.subjectIdList" multiple filterable clearable-->
<!--                     collapse-tags-->
<!--                     collapse-tags-tooltip placeholder="请选择" style="width: 250px">-->
<!--            <el-option-->
<!--                v-for="item in stateOptions.subjectTreeModeList"-->
<!--                :key="item.id"-->
<!--                :label="item.name"-->
<!--                :value="item.id"-->
<!--            />-->
<!--          </el-select>-->
<!--          {{state.subjectIdList}}-->
          <el-cascader v-model="state.subjectIdList"
                       :collapse-tags="true"
                       :props="cascaderProps"
                       :options="stateOptions.subjectTreeModeList"/>
        </div>

      </div>

    </div>
    <div class="main-row flex">
      <div class="row-tip" style="margin-right: 20px">
        <span style="white-space: nowrap">自设标签：</span>
      </div>
      <div style="padding-right: 50px;flex-wrap: wrap">
        <el-checkbox-group v-model="state.labelIdList">
          <el-tooltip
              :disabled="item.sid == 0"
              class="box-item"
              effect="light"
              placement="bottom"
              v-for="item in stateOptions.labelModelList"
          >
            <el-checkbox :value="item.id" :label="item.name" border
                         :style="`background:${item.colour};color:white;margin-right: 10px;margin-bottom: 10px;`"/>
            <template #content>
              <span class="delete-pop" @click="deleteLabel(item)">删除</span>
            </template>
          </el-tooltip>

          <i class="iconfont unify-tianjia_1 add-tip"
             @click="addTagHandlerClick"></i>
        </el-checkbox-group>

      </div>
    </div>


    <addTag ref="addTagRef" :addTagHandler="addTagHandler"/>
    <editTime ref="editTimeRef" :editTimeHandler="editTimeHandler"/>
    <el-affix position="bottom" :offset="20">
      <div class="button-container flex a-c j-c">
        <el-button color="#626aef" @click="submit">保存</el-button>
        <el-button color="#626aef" @click="reset" plain>重置</el-button>
      </div>
    </el-affix>

  </div>
</template>

<script>
import {MessageBox} from "@/js/utils/commonJs"
import {
  findManagerNavQueryParamList,
  findManagerNavSettingById,
  saveManagerNavSetting,
  deleteLabelById,
} from "@/js/network/main/index.js"
import {reactive, ref} from "vue";
import addTag from "./addTag"
import editTime from "./editTime"
import {useRoute} from "vue-router";
import {ElMessageBox} from "element-plus";

export default {
  components: {
    addTag,
    editTime
  },
  setup(props, {attrs, slots, emit, expose}) {
    const cascaderProps = {
      multiple:true,
      expandTrigger:'hover',
      children:'sonList',
      label:'name',
      value:'id',
    }
    const route = useRoute();
    const addTagRef = ref(null);
    const editTimeRef = ref(null);
    let iconList = ref([]);
    let stateOptions = ref({
      labelModelList: [],
      subjectIdList: [],
      documentTypeList:[]
    })
    //回显
    let state = ref({
      labelIdList: [],
      subjectIdList: [],
      propertyIdList:[]
    });
    let stateCopy = {labelIdList: [], subjectIdList: [],propertyIdList:[]};
    const cancelTopHandler = function () {
      state.value.topFlag = Math.abs(1 - state.value.topFlag);
    }
    const addTagHandlerClick = function () {
      addTagRef.value.init();
    }
    const editTimeHandlerClick = function () {
      if (state.value.openNewFlag != 1) {
        editTimeRef.value.init(state.value.openNewStartTimeStr, state.value.openNewEndTimeStr);
      } else {
        ElMessageBox.confirm('确定要执行本操作吗?').then((aaa) => {
          state.value.openNewFlag = 0;
        }).catch(() => {
        })

      }
    }
    const addTagHandler = function (item) {
      stateOptions.value.labelModelList.push(item)
    }
    const deleteLabel = function (item) {
      if (state.value.labelIdList.indexOf(item.id) > -1) {
        MessageBox.warning("请先取消选中");
        return
      }
      ElMessageBox.confirm('确定要执行本操作吗?').then((aaa) => {
        deleteLabelById({id: item.id}).then(res => {
          if (!res.success) {
            MessageBox.error(res.msg);
            return;
          }
          stateOptions.value.labelModelList = stateOptions.value.labelModelList.filter(r => r.id != item.id);
          MessageBox.success("删除成功！")
        }).catch(error => {
          //MessageBox.error(error);
          console.log(error, "catchError");
        });
      }).catch(() => {
      })


    }
    const editTimeHandler = function (stateObj) {
      state.value.openNewStartTimeStr = stateObj.startTime;
      state.value.openNewEndTimeStr = stateObj.endTime;
      state.value.openNewFlag = 1;
    }
    const choiceTag = function (id) {
      if (state.value.labelIdList.indexOf(id) > -1) {
        state.value.labelIdList = state.value.labelIdList.filter(r => r != id);
      } else {
        state.value.labelIdList.push(id);
      }
    }
    const init = function () {
      let {id} = route.query
      //获取下拉列表
      findManagerNavQueryParamList().then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        if (!res || !res.data) {
          return;
        }
        stateOptions.value = JSON.parse(JSON.stringify(res.data));
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error, "catchError");
      });
      findManagerNavSettingById({unifyVdatabaseDetailsId: id}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        if (!res || !res.data) {
          return;
        }
        state.value = {
          ...res.data,
          labelIdList: res.data.labelIdList || [],
          subjectIdList: res.data.subjectIdList || []
        };
        stateCopy = {
          ...res.data,
          labelIdList: res.data.labelIdList ? [...res.data.labelIdList] : [],
          subjectIdList: res.data.subjectIdList ? [...res.data.subjectIdList] : [],
          // labelModelList:res.data.labelModelList || []
        }
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error, "catchError");
      });

    }

    const reset = function () {
      state.value = {...stateCopy, labelIdList: JSON.parse(JSON.stringify(stateCopy.labelIdList))};
    }
    const submit = function () {
      ElMessageBox.confirm('确定要执行本操作吗?').then((aaa) => {
        if (aaa) {
          let {id} = route.query
          let subjectId = [];
          state.value.subjectIdList.forEach(item=>{
            if(Array.isArray(item)){
              subjectId = subjectId.concat(item)
            }else{
              subjectId.push(item)
            }
          })
          subjectId = [...new Set(subjectId)];
          let params = {
            ...state.value,
            unifyVdatabaseDetailsId: id,
            subjectIdList:subjectId
          }
          saveManagerNavSetting(params).then(res => {
            if (!res.success) {
              MessageBox.error(res.msg);
              return;
            }
            MessageBox.success("保存成功！");
            window.location.reload();
          }).catch(error => {
            //MessageBox.error(error);
            console.log(error, "catchError");
          });
        }
      }).catch(() => {
      })
    }

    const jumpTo = function (url) {
      window.open(url)
    }

    init();
    return {
      cascaderProps,
      addTagRef,
      editTimeRef,
      state,
      stateOptions,
      cancelTopHandler,
      addTagHandlerClick,
      editTimeHandlerClick,
      choiceTag,
      addTagHandler,
      deleteLabel,
      editTimeHandler,
      submit,
      reset,
      jumpTo
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.main-row {
  margin-bottom: 25px;
}

.row-tip {
  width: 90px;
  font-size: 14px;
  color: #adadad;
}

.row-content {
  width: 300px;
  position: relative;
  box-sizing: border-box;
  font-size: 14px;
  color: #000000;
  flex-wrap: wrap;

  .content-text {
    font-weight: 600;
  }

  .font-button {
    color: #0086b3;
    cursor: pointer;
    user-select: none;
    margin-left: auto;
    //position: absolute;
    //right: 0px;
    //top: 5px;
  }
}


.add-tip {
  font-size: 30px;
  color: #3157b9;
  cursor: pointer;
  user-select: none;
  position: relative;
  top: 5px;
}

.delete-pop {
  color: red;
  font-weight: 600;
  cursor: pointer;
}

:deep(.el-checkbox__label .is-checked) {
  color: #000000 !important;
  font-weight: 600;
}
</style>
