<template>
  <div class="dbConfig">
    <div class="menu flex" style="height: 70px;position: fixed;top: 0">
      <div class="item" :class="actStr=='readPromotionalReports'?'act':''" @click="routerChange('readPromotionalReports')">
        <i class="iconfont unify-daohangguanli"/>阅读推广报告
      </div>
    </div>
    <div class="view-container">
      <router-view/>
    </div>
  </div>
</template>

<script>
import {ref,reactive} from "vue";
import {useRoute} from "vue-router";
import router from "@/router"
export default {
  setup(props, {attrs,slots,emit,expose}) {
    const route = useRoute();
    const actStr = ref('readPromotionalReports');
    const routerChange = function (path){
      actStr.value = path;
      router.push('/readingPromotionCenter/'+path);
    }

    return {
      actStr,
      routerChange
    }

  }
}
</script>

<style scoped lang="scss">
.dbConfig {
  .menu {
    margin-left: 20px;

    .item {
      padding: 24px 0;
      margin-right: 80px;
      cursor: pointer;
      font-size: 15px;
      color: #424655;
      transition: 0.3s ease;

      &:hover{
        color: #2054D6;
      }

      .iconfont{
        margin-right: 4px;
      }

      &.act {
        color: #2054D6;
        font-weight: 600;
      }
    }
  }
}

</style>
