<template>
    <div>
    </div>
</template>

<script>

export default {
    name: "Login",
    data() {
        return {

        };
    },
    watch: {

    },
    created() {
        //打开ers单点登录
        this.initPage()
    },
    methods: {
        initPage() {
            this.$nextTick(() => {
                let url = this.$route.query.redirect;
                if (this.$utils.isBlank(url)) {
                    url = "/"
                }
              location.href = `${process.env.VUE_APP_LOGIN_URL}/sso-server/index?origin=${this.$utils.encodeUrl(location.href)}
                &target=${this.$utils.encodeUrl(url)}&callBack=${this.$utils.encodeUrl(location.origin + '/302')}`;
            })
        }
    }
};
</script>

<style rel="stylesheet/scss" lang="scss">

</style>
