//产品
import require from "@/js/network/require.js";
import {handleError} from "@/js/utils/commonJs";

/**
 * Description:全局设置开关
 * @param unifyGlobalSettingQueryModel:条件
 * @return com.kcidea.common.common.result.Result<java.lang.String>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/1/31 10:20
 **/
var checkLogin = function () {
    return require.get('main-server/login/checkLogin',{
        // params,
    }).catch(error=>{
        handleError(error)
    });
}
/**
 * Description:查询全局设置
 * @return com.kcidea.common.common.result.Result<com.kcidea.main.model.UnifyGlobalSettingModel>
 * @Author: qiangyin@kcidea.com
 * @Date 2024/1/26 10:58
 **/
var loginOut = function () {
    return require.get('main-server/login/loginOut',{
        // params,
    }).catch(error=>{
        handleError(error)
    });
}
export {
    checkLogin,
    loginOut,
};