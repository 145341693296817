import router , { DynamicRouting , notFoundRoute } from '@/router/index.js';
import {findMenuListBySchoolId} from "@/js/network/main";
import {MessageBox} from "@/js/utils/commonJs";

const state = {
    // 路由
    navRoutes: [],

    addRoute:[]

}

const mutations = {
    setNavRoutes(state ,  val){
        state.navRoutes = val;
    },
    setAddRoute(state ,  val){
        state.addRoute = val;
    },
    
}

const actions = {
    generateRoutes(store) {
        return new Promise(( resolve , reject ) => {
            findMenuListBySchoolId().then(res => {
                // 路由权限
                const routePermissionList = res.data;
                store.commit("setNavRoutes", routePermissionList)
                let routeList = [];
                if(routePermissionList && routePermissionList.length){
                    routePermissionList.forEach( routeKey => {
                        const routeData = DynamicRouting[routeKey['path']];
                        if(routeData){
                            mapToList(routeData , routeList);
                        }
                    } )
                }
                
                 store.commit("setAddRoute", routeList)
                resolve(routeList);
            })
        })
        
    }
}


function mapToList(route , routeList){
    routeList.push({
         parentName:route.parentName,
         path: route.path,
         name:route.name,
         component: route.component,
         redirect:route.redirect || ''
    })
    if(route.children && route.children?.length){
        route.children.forEach(v => {
            mapToList(v , routeList)
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}