import axios from 'axios';
import router from "@/router"


// 需要取消的接口
let cancelNameList = [
    'main-server/unifyVdatabaseDetails/findDatabaseManageList',
    'main-server/user/findRegisterUserListPage'
];
//取消方法栈
let cancelAxios = {};

// 自定义Token取消方法栈
let ticketCancelAxios = {};
/* 创建axios实例 */
const service = axios.create({
    headers: {
        contentType: 'application/x-www-form-urlencoded;charset=UTF-8',//默认是utf表单
        accessToken:localStorage.getItem("accessToken")
    },
    baseURL:"/serve",
});
const myAxios = function (axiosObj) {
    if(axiosObj.headers){
        axiosObj.headers.accessToken = localStorage.getItem("accessToken")
    }else {
        axiosObj.headers= {
            accessToken:localStorage.getItem("accessToken")
        }
    }
    //是否关闭取消
    return service(axiosObj);
}
service.interceptors.request.use((config)=>{
    let url = config.url;
    if (cancelNameList.indexOf(url) != -1) {
        config.cancelToken = new axios.CancelToken(cancel => {
            let cancelF = cancelAxios[url];
            if (cancelF) {
                cancelF("Request Canceled!");
            }
            cancelAxios[url] = cancel;
        })
    }

    //删除空字段
    if (config.method == 'post' && config.data.params) {
        config.responseType = config.data.responseType;
        config.data = config.data.params;

        for (let key in config.data) {
            if (config.data[key] === null || config.data[key] === undefined || config.data[key] === "") {
                delete config.data[key];
            }
        }
    }
    /* GET请求的参数转义 */
    if (config.params != null && config.method == 'get') {
        let keys = Object.keys(config.params);
        keys.forEach(key => {
            if (config.params[key] === null || config.params[key] === undefined || config.params[key] === "") {
                delete config.params[key];
            }

        })
    }



    return config;
},error => {
    console.log(error,"reject-error")
    console.info(error);
    return Promise.reject(error);
})
service.interceptors.response.use(response=>{
    //请求成功，201为下载文件状态码
    if (response.status == 200 || response.status == 201) {
        console.log(response.config.responseType,"response.config.responseType");
        if (response.config.responseType == "blob") {
            return response;
        }
        return response.data;
    }else{
        return response;
    }
},error => {
    let {response} = error
    if (response && response.status == 401) {
        localStorage.removeItem('accessToken');
        //当用户登录凭证过期或者推出登录时，返回login页面
        let query = window.location.search
        let queryObj = {
            path:"/login",
            query:query ? query.substring(1) : ""
        }
        if(queryObj.query==""){
            delete queryObj.query
        }
        let routeUrl = router.resolve(queryObj);
        window.open(routeUrl.href, "_self");
    } else if (response && response.status == 403) {
        return Promise.reject({flag: false, message: "%~%操作失败，没有该操作权限！"});
    } else {
        return Promise.reject(error);
    }
})


export default service;