<template>
  <div class="databaseNav">
    <div class="header-row flex a-c">
      <div class="input-container flex a-c">
        <div class="time-select-button" :class="timeType==1?'checked':''" @click="changeTime(1)">今日</div>
        <div class="time-select-button" :class="timeType==2?'checked':''" @click="changeTime(2)">近七日</div>
        <el-date-picker
            v-model="value2"
            @change="pickerTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="YYYY-MM-DD"
           
            value-format="YYYY-MM-DD"
        >
        <!--  date-format="YYYY/MM/DD ddd"
            time-format="A hh:mm:ss" -->
        </el-date-picker>
      </div>
<!--      <el-button type="primary" style="margin-left: auto">报告导出</el-button>-->
    </div>

    <div class="main-container flex">

      <div class="main-left">
        <div class="count-show" v-loading="clickNumLoading">
          <div class="count-block">
            <div class="count-value">{{ clickNum.databaseClickNum }}</div>
            <div class="count-name">数据库点击量</div>

          </div>

          <div class="count-block">
            <div class="count-value">{{ clickNum.urlClickNum }}</div>
            <div class="count-name">链接点击量</div>

          </div>
          <div class="count-block">
            <div class="count-value">{{ clickNum.independentNum }}</div>
            <div class="count-name">独立访客</div>

          </div>
          <div class="count-block">
            <div class="count-value">{{ clickNum.activeNum }}</div>
            <div class="count-name">活跃注册用户</div>

          </div>


        </div>
        <div class="table-container">
          <el-table :data="tableList"
                    v-loading="tableListLoading"
                    :default-sort="{prop: 'databaseClickNum', order: 'descending'}"
                    border
                    max-height="calc(100vh - 360px)"
                    min-height="300px"
                    stripe style="width: 100%"
                    class="my-table">
            <el-table-column type="index" width="50"/>
            <el-table-column label="数据库">
              <template #default="scope">
                {{ scope.row.databaseName }}
              </template>
            </el-table-column>
            <el-table-column prop="databaseClickNum" label="数据库点击量" sortable  >
              <template #default="scope">
                {{ scope.row.databaseClickNum }}
              </template>
            </el-table-column>
            <el-table-column prop="urlClickNum" label="链接点击量" sortable>
              <template #default="scope">
                {{ scope.row.urlClickNum }}
              </template>
            </el-table-column>
            <el-table-column prop="independentNum" label="独立访客" sortable>
              <template #default="scope">
                {{ scope.row.independentNum }}
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="main-right">
        <div class="echarts-container flex">
          <div class="echarts-type">
            <commonEcharts propId="echarts-type" ref="charts1" echarts-height="300px"/>
          </div>
          <div class="echarts-language">
            <commonEcharts propId="echarts-language" ref="charts2" echarts-height="300px"/>
          </div>
        </div>
        <div class="echarts-bar">
          <commonEcharts propId="echarts-bar" ref="charts3" echarts-height="500px"/>
        </div>
      </div>

    </div>
    <div>

    </div>

  </div>
</template>

<script>
import {ref,onMounted} from "vue"
import {
  countClickNum,
  findListCountClickNum,
  countByLanguageType,
  countByType,
  countByCardTypeName
} from "@/js/network/main"
import {MessageBox} from "@/js/utils/commonJs";
import commonEcharts from "./commonEcharts"

const moment = require('moment')

export default {
  components: {
    commonEcharts
  },
  setup(props, context) {
    const charts1 = ref(null);
    const charts2 = ref(null);
    const charts3 = ref(null);
    const value2 = ref([]);
    const uniValue = ref([]);
    // const valueType = ref(0);
    const timeType = ref(null);

    const clickNum = ref({});
    const clickNumLoading = ref(true);
    const tableList = ref([]);
    const tableListLoading = ref(true);

    const typeEchartsData = ref([]);
    const languageEchartsData = ref([]);
    const barEchartsData = ref([]);
    const pickerTime = function (){
      // valueType.value = 0;
      uniValue.value = [];
      timeType.value = null;
      init();
    }
    const changeTime = function (type) {
      // valueType.value = 1;
      value2.value = [];
      timeType.value = type;
      if (type == 1) {
        // 获取今天的日期并格式化为 "YYYY-MM-DD" 格式
        const today = moment().format('YYYY-MM-DD');

        // 获取今天的开始时间和当前时间
        // const currentTime = moment();

        // 构建时间字符串数组
        value2.value = [today , today];
        // [
        //   today+" 00:00:00",
        //   currentTime.format('YYYY-MM-DD HH:mm:ss')
        // ];
      }
      if (type == 2) {

// 获取过去七天的日期并格式化为 "YYYY-MM-DD" 格式
        const sevenDaysAgo = moment().subtract(6, 'days').format('YYYY-MM-DD');

// 获取今天的开始时间和当前时间
        const currentTime = moment().format('YYYY-MM-DD');

// 构建时间字符串数组
        value2.value = [
          sevenDaysAgo,
          currentTime
        ];

      }
      init();
    }
    const init = function () {
      let arr = [];
      // if(valueType.value==0){
        arr = value2.value || [];
      // }
      // if(valueType.value==1){
      //   arr = uniValue.value;
      // }
      clickNumLoading.value = true;
      countClickNum({startTime: arr[0], endTime: arr[1]}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          clickNumLoading.value = false;
          return;
        }
        if (!res || !res.data) {
          clickNumLoading.value = false;
          return;
        }
        clickNum.value = res.data;
        clickNumLoading.value = false;
      }).catch(error => {
        clickNumLoading.value = false;
        console.log(error, "catchError");
      });
      tableListLoading.value = true;
      findListCountClickNum({startTime: arr[0], endTime: arr[1]}).then(res => {

        if (!res.success) {
          MessageBox.error(res.msg);
          tableListLoading.value = false;
          return;
        }
        if (!res || !res.data) {
          tableListLoading.value = false;
          return;
        }
        tableList.value = res.data;
        tableListLoading.value = false;
      }).catch(error => {
        tableListLoading.value = false;
        console.log(error, "catchError");
      });
      charts2.value.loading();
      countByLanguageType({startTime: arr[0], endTime: arr[1]}).then(res => {
        if (!res.success) {
          charts2.value.hideLoading();
          MessageBox.error(res.msg);
          return;
        }
        if (!res || !res.data) {
          charts2.value.hideLoading();
          return;
        }
        languageEchartsData.value = res.data;
        let options = initLanguageOptions(res.data);

        charts2.value.setOption(options);

      }).catch(error => {
        charts2.value.hideLoading();
        console.log(error, "catchError");
      });
      charts1.value.loading();
      countByType({startTime: arr[0], endTime: arr[1]}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          charts1.value.hideLoading();
          return;
        }
        if (!res || !res.data) {
          charts1.value.hideLoading();
          return;
        }
        typeEchartsData.value = res.data;
        let options = initTypeOptions(res.data);

        charts1.value.setOption(options);
      }).catch(error => {
        charts1.value.hideLoading();
        console.log(error, "catchError");
      });
      charts3.value.loading();
      countByCardTypeName({startTime: arr[0], endTime: arr[1]}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          charts3.value.hideLoading();
          return;
        }
        if (!res || !res.data) {
          charts3.value.hideLoading();
          return;
        }
        barEchartsData.value = res.data;
        let options = initBarOptions(res.data);

        charts3.value.setOption(options);
      }).catch(error => {
        charts3.value.hideLoading();
        console.log(error, "catchError");
      });
    }
    const initLanguageOptions = function (data) {
      let option = {
        title: {
          text: '语种分布',
          left: 'left'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
        },
        series: {
          type: 'pie',
          radius: [20, 80],
          center: ['50%', '50%'],
          roseType: 'area',
          itemStyle: {
            borderRadius: 5
          },
          data: data.sort((a, b) => b.value - a.value)
        }

      };
      return option;
    }
    const initTypeOptions = function (data) {
      let option = {
        title: {
          text: '类型分布',
          left: 'left'
        },
        tooltip: {
          trigger: 'axis',
          formatter: '{b} : {c} ({d}%)',
        },
        series: {
          type: 'pie',
          radius: [20, 80],
          center: ['50%', '50%'],
          roseType: 'area',
          itemStyle: {
            borderRadius: 5
          },
          data: data.sort((a, b) => b.value - a.value)
        }

      };
      return option;
    }
    const initBarOptions = function (data) {
      let xA = [];
      let dataA = [];
      let linkA = []
      data.map(r => {
        xA.push(r.cardTypeName);
        dataA.push(r.countModelList[0].count);
        linkA.push(r.countModelList[1].count);
      })
      let option = {
        title: {
          text: '读者卡统计',
          left: 'left'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            formatter: function (param) {
              console.log(param,"params")
              param = param[0];
              return [
                '数据库点击: ' + param.data[0] + '<br/>',
                '链接点击: ' + param.data[1] + '<br/>',
              ].join('');
            }
          },

        },
        legend: {
          data: ['数据库点击', '链接点击']
        },
        xAxis: {
          show: true,
          type: 'category',
          data: xA,
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}'
          }
        },
        grid: {
          left: "10px",
          right: "10px",
          bottom: "25%",
          top: "10%",
          containLabel: true,
        },
        series: [
          {
            name: '数据库点击',
            type: 'bar',
            data: dataA
          },
          {
            name: '链接点击',
            type: 'bar',
            data: linkA
          },
        ]
      };
      return option;
    }
    onMounted(()=>{
      init();
    })

    return {
      clickNum,
      clickNumLoading,
      tableList,
      tableListLoading,
      barEchartsData,
      languageEchartsData,
      typeEchartsData,
      value2,
      uniValue,
      timeType,
      charts1,
      changeTime,
      pickerTime,
      init,
      initLanguageOptions,
      initTypeOptions,
      charts2,
      charts3
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header-row {
  margin-top: 12px;
  margin-bottom: 20px;
}

.input-container {
  border: 1px solid #dcdfe6;
  width: 500px;

  :deep(.el-range-editor) {
    height: 28px;
    box-shadow: none;
  }

  :deep(.el-range-editor:hover) {
    box-shadow: none;
  }
}

.time-select-button {
  width: 90px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  color: #a8abb2;
  font-size: 14px;
  border-right: 1px solid #dcdfe6;
  cursor: pointer;
  &.checked{
    background-color: #2054D6;
    color: #ffffff;
  }
}


.main-container {

}

.main-left {
  width: calc(50% - 15px);
  margin-right: 30px;
}

.count-show {
  padding: 12px 32px 12px 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
  background: linear-gradient(137deg, #fbfcfe 0%, #f6f7fb 65%, #ffffff 100%);
  box-shadow: -4px -4px 8px 0px rgba(236, 239, 245, 0.47),
  4px 4px 8px 0px rgba(183, 192, 201, 0.24);
  height: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
}

.count-block {
  //height: 80px;
  position: relative;
}

.count-name {
  text-align: center;
  color: #94a3b2;
  font-size: 14px;
}

.count-value {
  text-align: center;
  color: #34495e;
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 10px;
}

.main-right {
  width: calc(50% - 15px);
}

.echarts-container {
  width: 100%;
}

.echarts-type {
  width: 100%;
}

.echarts-language {
  width: 100%;
}

</style>
