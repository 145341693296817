<!-- 海报分享 -->
<template>
  <custom-dialog v-model="visible" @open="open" size='small' title="招募渠道">
    <div class="poster-share" v-loading="loading">
      <div class="poster-box">
        <div id="code"></div>
        <canvas id="poster" width="400" height="532"></canvas>
      </div>

      <div class="copy">
        <span class="text">{{ code }}</span>
        <!-- <span @click="copy">复制</span> -->
         <i class="iconfont unify-copy copy-icon" @click="copy"></i>
      </div>
      <div class="download-poster">
        <el-button class="primary-btn" type="primary" @click="downloadPoster">
          下载海报
          <i class="iconfont unify-download" style="margin-left: 4px"></i>
        </el-button>
      </div>
    </div>
  </custom-dialog>
</template>
<script setup>
import { getAuthorizeCode } from "@/js/network/main"
import {MessageBox} from "@/js/utils/commonJs";
import { ref } from "vue";
import share from "@/assets/kc-unify-share.png";
import QRCode from "qrcodejs2-fix";
import  useClipboard  from 'vue-clipboard3';
const { toClipboard } = useClipboard();

// 双向绑定
const visible = defineModel({ type: Boolean, default: false });
const loading = ref(false);
const code = ref('');
let canvas;
let ctx;

async function open() {
  if(code.value) return;
   loading.value = true;
    await getAuthorizeCodeFn()  
  canvas = document.getElementById("poster");
  ctx = canvas.getContext("2d");
  getCode();
  const myImg = new Image();
  myImg.src = 'https://miniopreview.resourcemap.com.cn/kc-unify/web/kc-unify-share.png';
  myImg.crossOrigin = 'anonymous';
  // myImg.src = share;
  myImg.onload = function () {
    ctx.drawImage(this, 0, 0 , canvas.width, canvas.height);
  };

}

const getCode = () => {
  document.getElementById("code").innerHTML = ""; //清空标签内原有数据，防止多次点击生成多个二维码
  new QRCode(document.getElementById("code"), {
    text:code.value,
    complete:()=>{
      console.log('e');
    }
  } );
  let timer = setTimeout(() => {
    ctx.drawImage(
      document.getElementById("code").getElementsByTagName("img")[0],
      282,
      386,
      92,
      92
    );
   loading.value = false;
   clearTimeout(timer);
  }, 1000);
};

function downloadPoster() {
  var link = document.createElement("a");
  var imgData = canvas.toDataURL({
    format: "png",
    quality: 1,
    width: 600,
    height: 400,
  });
  var strDataURI = imgData.substr(22, imgData.length);
  var blob = dataURLtoBlob(imgData);
  var objurl = URL.createObjectURL(blob);
  link.download = "grid1.png";
  link.href = objurl;
  link.click();
}

function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

// 获取二维码
const getAuthorizeCodeFn = async () =>{
    try {
        let res = await getAuthorizeCode();
         if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        let baseUrl = process.env.NODE_ENV != 'development' ? "https://promotion.resourcemap.com.cn/recruitmentPromotionExperts?schoolId=" : "http://192.168.1.142:6565/recruitmentPromotionExperts?schoolId="
        code.value = baseUrl + res.data;

    } catch (error) {
        loading.value = false;
    }
}

const copy = async () => {
  await toClipboard(code.value);
  MessageBox.success('复制成功');
}



</script>

<style lang="scss" scoped>
.poster-share {
  .copy{
      border-radius: 6px 6px 6px 6px;
      border: 1px solid rgba(0,0,0,0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      box-sizing: border-box;
      padding: 0 12px;
      margin-top: 16px;

      .text{
        font-size: 14px;
        color: rgba(0,0,0,0.4);
      }
      .copy-icon{
        color: rgba(0,0,0,0.4);
        cursor: pointer;
      }
    }
  .poster-box {
    display: flex;
    justify-content: center;
    #code {
      position: fixed;
      left: 0;
      top: 99999px;
    }

    
  }
  .download-poster{
    // margin: 0 auto;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    // .primary-btn{
    //   background-color: #235BE7;
    //   font-size: 14px;
    //   color: #FFFFFF;
    //   border: unset;
    //   border-radius: 8px;
    //   height: 36px;
    //   padding: 0 22px;
    // }
  }
}
</style>
