<script setup>
import { defineExpose,ref } from 'vue'

import { VuePDF, usePDF } from 'vue-pdf-2.16.105'

const { pdf, pages, info } = usePDF('https://miniopreview.resourcemap.com.cn/ersmap-platform/photo-default/document.pdf')
const dialogVisible = ref(false);
const handleClose = () => {
  dialogVisible.value = false;
}
const init = function (){
  dialogVisible.value = true;
}

// 显式导出 init 和 silentClick 方法
defineExpose({ init });

// console.log(`Document has ${pages} pages`)
// console.log(`Document info: ${info}`)
</script>

<template>
  <el-dialog
      v-model="dialogVisible"
      title="新建标签"
      width="900px"
      :before-close="handleClose"
  >
    <VuePDF :pdf="pdf" />
  </el-dialog>

</template>