<!-- 审核弹窗 -->
<template>
  <custom-dialog
    v-model="visible"
    title="审核"
    @open="open"
    size="small"
  >
    <div class="audit-box">
      <div class="flex a-c row">
        <div class="label">姓名：</div>
        <div class="text">{{ auditDetail.name }}</div>
      </div>
      <div class="flex a-c row">
        <div class="label">身份：</div>
        <div class="text">{{ auditDetail.role }}</div>
      </div>
      <div class="flex a-c row">
        <div class="label">所属学院/部门：</div>
        <div class="text">{{ auditDetail.org }}</div>
      </div>
      <div class="row" v-if="auditDetail.fileList && auditDetail.fileList.length">
        <div class="label">认证材料:</div>
        <div class="image-box">
          <el-image
            class="image"
            v-for="(url , index) in auditDetail.fileList"
            :key="index"
            :src="url"
            :preview-src-list="auditDetail.fileList"
            fit="cover"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex a-c" style="justify-content: flex-end">
        <el-button
          type="primary"
          plain
          class="primary-pain-btn"
          :loading="buttonLoading"
          @click="auditClick(2)"
          >驳回</el-button
        >
        <el-button
          type="primary"
          class="primary-btn"
          :loading="buttonLoading"
          @click="auditClick(1)"
          >通过</el-button
        >
      </div>
    </template>
  </custom-dialog>
</template>

<script setup>
import {
  recruitRecordFindOneAuditDetailById,
  promotionUserRecruitRecordAudit,
} from "@/js/network/main";
import { MessageBox } from "@/js/utils/commonJs";
import { ElMessageBox } from "element-plus";
import { ref, reactive , toRefs} from "vue";
const emit = defineEmits(['update:value']);

// 双向绑定
const visible = defineModel({ type: Boolean, default: false });
const loading = ref(false);
const buttonLoading = ref(false);
let auditDetail = reactive({});

const props = defineProps({
  id: {
    type: [String, Number],
    default: "",
  },
});

function findOneAuditDetailById() {
  loading.value = true;
  recruitRecordFindOneAuditDetailById({ id: props.id })
    .then((res) => {
      loading.value = false;
      if (!res.success) {
        MessageBox.error(res.msg);
        return;
      }
      auditDetail = Object.assign(auditDetail, res.data);
    })
    .catch((error) => {
      loading.value = false;
      console.log(error, "catchError");
    });
}

function auditClick(state) {

  // 驳回
  if (state === 2) {
    ElMessageBox.prompt("请输入驳回原因！", "提示", {
      confirmButtonText: "提交",
      cancelButtonText: "取消",
      inputPattern: /\S/,
      inputErrorMessage: "驳回理由不能为空！",
    })
      .then(({ value }) => {
        audit(state, value);
      })
      .catch(() => {});

    // 通过
  } else if (state === 1) {
    audit(state);
  }
}

function audit(state, reason = "") {
  buttonLoading.value = true;
  promotionUserRecruitRecordAudit({
    id: props.id,
    state: state,
    reason: reason,
  })
    .then((res) => {
      buttonLoading.value = false;
      if (!res.success) {
        MessageBox.error(res.msg);
        return;
      }
      MessageBox.success('操作成功！');
      beforeClose()
    })
    .catch((error) => {
      buttonLoading.value = false;
      console.log(error, "catchError");
    });
}

function beforeClose(){
    emit('update');
    visible.value = false;
}

function reset(){
    
}

function open() {
  if (props.id) {
    findOneAuditDetailById();
  }
}
</script>

<style lang="scss" scoped>
.audit-box {
  padding: 0 9px;
  height: 369px;
  overflow-y: auto;
  .row {
    margin-bottom: 24px;
    .label {
      font-size: 14px;
      color: #999999;
    }
    .text {
      font-size: 14px;
      color: #000000;
    }

    .image-box {
      margin-top: 12px;
      display: flex;
      flex-wrap: wrap;

      .image{
        width: 170px; 
        height: 120px;
        // margin-right: ;
        margin-right: 12px;
        border-radius: 10px;
        margin-bottom: 12px;

        // :last-child()
        &:nth-child(3n + 3){
          margin-right: 0;
        }
      }
    }
  }
}
</style>
