<template>
  <el-dialog
      v-model="dialogVisible"
      title="添加馆员"
      width="30%"
      :before-close="handleClose"
  >
    <div class="main-container">
      <div class="row  flex a-c">
        <div class="row-tip"><i class="iconfont unify-bitian"></i>名称:</div>
        <el-input style="width: 220px" v-model="state.name" placeholder="馆员名称"/>
      </div>
      <div class="row  flex a-c">
        <div class="row-tip">称谓:</div>
        <el-input style="width: 220px" v-model="state.nickName" placeholder="馆员称谓"/>
      </div>
      <div class="row flex a-c">
<!--        <el-input style="width: 220px" v-model="state.photo" placeholder="头像"/>-->
<!--        <el-button type="primary">-->
<!--          上传头像<el-icon class="el-icon&#45;&#45;right"><Upload /></el-icon>-->
<!--        </el-button>-->
        <div class="row-tip">头像:</div>
        <el-upload
            class="avatar-uploader"
            action="action"
            :http-request="uploadFile"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            accept=".jpg,.png,.jpeg,"
        >
          <img v-if="state.photo" :src="state.photo" class="avatar" />
          <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>
      </div>
      <div class="row  flex a-c">
        <div class="row-tip">邮箱:</div>
        <el-input style="width: 220px" v-model="state.mail" placeholder="馆员邮箱"/>
      </div>
      <div class="row  flex a-c">
        <div class="row-tip">电话:</div>
        <el-input style="width: 220px" v-model="state.phone" placeholder="馆员电话"/>
      </div>
      <div class="row  flex a-c">
        <div class="row-tip">地址:</div>
        <el-input style="width: 220px" v-model="state.address" placeholder="馆员地址"/>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmHandler">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {reactive,ref} from 'vue'
import {addLibrarian, uploadFileByMultipartFile,findOneLibrarianById} from "@/js/network/main/index"
import { Plus } from '@element-plus/icons-vue'
import {MessageBox} from "@/js/utils/commonJs";
import { ElMessage } from 'element-plus'

export default {
  components:{
    Plus
  },
  setup(props, {attrs, slots, emit, expose}) {
    const state = reactive({
      id:"",
      name:"",
      nickName: "",
      photo:"",
      mail:"",
      phone:"",
      address:"",
    })
    const dialogVisible  = ref(false)

    const handleClose = () => {
      Object.keys(state).forEach(key => {
        delete(state[key]);
      });
      dialogVisible.value = false;
    }
    const init = (id) => {
      if(id){
        findOneLibrarianById({id}).then(res=>{
          if (!res.success) {
            MessageBox.error(res.msg);
            return;
          }
          if (!res || !res.data) {
            return;
          }
          Object.assign(state, res.data);
        }).catch(error => {
          //MessageBox.error(error);
          console.log(error,"catchError");
        });
        dialogVisible.value = true;
        return;
      }
      dialogVisible.value = true;
    }
    const confirmHandler = () => {
      addLibrarian({...state}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        dialogVisible.value = false;
        MessageBox.success('添加成功!');
        emit("success");
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error,"catchError");
      });
    }
    const uploadFile = function (param) {
      var form = new FormData();
      form.append("file", param.file);

      uploadFileByMultipartFile(form)
          .then((res) => {
            if (!res.success) {
              MessageBox.error(res.msg);
              return;
            }
            state.photo = res.data.url;
            MessageBox.success('上传成功!');
          })
          .catch((error) => {
          });
    }
    const beforeAvatarUpload = function(rawFile){
      const isJPG = rawFile.type == "image/jpeg"  || rawFile.type == "image/png" || rawFile.type == "image/jpg";
      const isLt5M = rawFile.size / 1024 / 1024 < 5;
      if(!isJPG){
        ElMessage.error('文件格式错误！');
        return false;
      }
      if(!isLt5M){
        ElMessage.error('文件大小超过5MB！');
        return false;
      }
      return true;
    }
    return {
      state,
      dialogVisible,
      handleClose,
      init,
      confirmHandler,
      beforeAvatarUpload,
      uploadFile,
      ...props
    }

  }
}


</script>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
<style scoped lang="scss">
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
//:deep(.el-input__wrapper) {
//  box-shadow: none !important;
//  background: transparent !important;
//}
.main-container{

}
.row{
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 12px;
  .row-tip{
    font-size: 14px;
    color: #333333;
    font-weight: 600;
    margin-right: 12px;
    position: relative;
    .unify-bitian{
      color: red;
      position: absolute;
      left: -15px;
      top: 3px;
    }
  }
}
</style>
