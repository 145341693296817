let iconEnums = [
    {
        value:1,
        name:"置顶",
        imageUrl:"https://miniopreview.resourcemap.com.cn/ersmap-platform/system/unify/toTop.png"
    },
    {
        value:2,
        name:"待配置",
        imageUrl:"https://miniopreview.resourcemap.com.cn/ersmap-platform/system/unify/tobeEdit.png"
    },
]
export {
    iconEnums
}