<template>
  <div class="catalogingLogs">
    <el-timeline style="max-width: 900px;margin-bottom: 15px" v-loading="logListLoading">
      <el-timeline-item
          v-for="(activity, index) in logList"
          :key="index"
          :timestamp="activity.opTimeStr"
          placement="top"
      >
        <div class="card">
          <div class="card-title">{{ activity.moduleName }}</div>
          <div class="card-content">
            <div class="content-row" v-for="item in activity.opTips" v-html="item"></div>
          </div>
          <div class="user-name">{{ activity.opUserName }}</div>
        </div>
      </el-timeline-item>
      <el-timeline-item style="cursor: pointer" @click="numberAdd" v-if="logList.length <total">
        更早 <i class="iconfont unify-zhankaitubiao" style="position: relative;top: 2px" ></i>
      </el-timeline-item>
    </el-timeline>

  </div>
</template>

<script>
import {
  findLogListByUnifyVdatabaseDetailsId,
} from "@/js/network/main/index.js"
import {reactive, ref} from "vue";
import {MessageBox} from "@/js/utils/commonJs";
import {useRoute} from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const logList = ref([]);
    const logListLoading = ref(true);
    const pageSize = ref(10);
    const pageNum = ref(1);
    const total = ref(0)
    const numberAdd = function () {
      pageNum.value = pageNum.value + 1;
      getLog();
    }

    //获取数据
    const getLog = function () {
      let {id} = route.query
      logListLoading.value = true;
      findLogListByUnifyVdatabaseDetailsId({unifyVdatabaseDetailsId: id,pageSize:pageSize.value,pageNum:pageNum.value}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          logListLoading.value = false;
          return;
        }
        if (res && res.data) {
          res.data.map(r => {
            let strArr = r.opTips.split("$$");
            r.opTips = strArr;
            logList.value.push(r);
            return r;
          });
          logListLoading.value = false;
        }
        total.value = parseInt(res.count);
      }).catch(error => {
        logListLoading.value = false;
        //MessageBox.error(error);
        console.log(error, "catchError");
      });
    }
    getLog();
    return {
      total,
      logList,
      logListLoading,
      pageSize,
      pageNum,
      numberAdd
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.card {
  padding: 5px 0 15px 0;
}

.card-title {
  font-size: 14px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 12px;
}

.card-content {
  color: #5F5F5F;
  font-size: 14px;
  margin-bottom: 8px;
}

.content-row {
  margin-bottom: 5px;
}

.user-name {
  font-size: 12px;
  color: #5F5F5F;
}

</style>
