<template>
  <div class="GlobalSetting">
    <div class="main-row">
      <div class="title-row flex a-c j-s">
        <div class="title-text">版权公告</div>
        <el-switch
            @change="confirm"
            :active-value="1"
            :inactive-value="0"
            v-model="state.params.copyrightNoticeFlag"
            :before-change="beforeChange"
            active-color="#2054D6"
            size="large"
        />
      </div>
      <div class="row-container">
        该设置为控制数据库导航功能页面中的版权公告是否显示
      </div>
    </div>
    <div class="divider"></div>
    <div class="main-row">
      <div class="title-row flex a-c j-s">
        <div class="title-text">导航责任馆员</div>
        <el-switch
            @change="confirm"
            :active-value="1"
            :inactive-value="0"
            v-model="state.params.navManagerFlag"
            :before-change="beforeChange"
            active-color="#2054D6"
            size="large"
        />
      </div>
      <div class="input-div">
        <el-select
            filterable clearable
            :disabled="state.params.navManagerFlag==1"
            @change="confirm"
            v-model="state.params.navLibrarianId"
            class="m-2"
            placeholder="请选择馆员"
            style="width: 240px"
        >
          <el-option
              v-for="item in state.librarianOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
          />
        </el-select>
      </div>
      <div class="row-container">
        该设置为控制数据库导航列表处责任馆员是否显示，如果需要切换责任馆员，您需要先关闭再进行变更
      </div>
    </div>
    <div class="divider"></div>
    <div class="main-row">
      <div class="title-row flex a-c j-s">
        <div class="title-text">数据库责任馆员</div>
        <el-switch
            @change="confirm"
            :active-value="1"
            :inactive-value="0"
            v-model="state.params.databaseManagerFlag"
            :before-change="beforeChange"
            active-color="#2054D6"
            size="large"
        />
      </div>
      <div class="row-container">
        该设置为控制数据库导航详情页处责任馆员是否显示
      </div>
    </div>
    <div class="divider"></div>
    <div class="main-row">
      <div class="title-row flex a-c j-s">
        <div class="title-text">资源图谱</div>
        <el-switch
            @change="confirm"
            :active-value="1"
            :inactive-value="0"
            v-model="state.params.resourceMappingFlag"
            :before-change="beforeChange"
            active-color="#2054D6"
            size="large"
        />
      </div>
      <div style="margin-bottom: 20px" class="row-container">
        该设置需要我馆开通“学术资源地图”产品，开启该功能，在数据库导航详情页中可为用户提供数据库内资源的检索、学科导航等服务
      </div>
      <el-image style="width: 252px; height: 174px"
                src="https://miniopreview.resourcemap.com.cn/ersmap-platform/system/unify/subjectTreeMap.png"/>
    </div>
    <div class="divider"></div>
  </div>
</template>

<script>

import {reactive, ref} from "vue";
import {ElMessageBox} from "element-plus"
import {updateSystemGlobalSettingBySid, findOneGlobalSettingBySid, findLibrarianList} from "@/js/network/main"
import {MessageBox} from "@/js/utils/commonJs";

export default {
  setup(props, {attrs, slots, emit, expose}) {
    let isFirst = ref(true);
    let state = reactive({
      params: {},
      librarianOptions:[],
    })
    const confirm = () => {
      if(isFirst.value){
        return;
      }
      updateSystemGlobalSettingBySid(state.params).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        MessageBox.success("操作成功！");
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error,"catchError");
      });
    }
    const beforeChange = function () {
      isFirst.value = false;
      return new Promise((resolve, reject) => {
        ElMessageBox.confirm('确定要执行本操作吗?').then((aaa) => {
          if (aaa) {
            resolve(true);

          }
          reject(false);
        }).catch(() => {
          reject(false);
        })
      })

    }
    const init = function () {
      findLibrarianList().then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        if(!res || !res.data){
          return;
        }
        state.librarianOptions = res.data;
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error,"catchError");
      });
      findOneGlobalSettingBySid().then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        if(!res || !res.data){
          return;
        }
        state.params = res.data;
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error,"catchError");
      });
    }
    init();
    return {
      isFirst,
      state,
      init,
      beforeChange,
      confirm
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.GlobalSetting{
  padding-right: 20px;
  box-sizing: border-box;
  height: calc(100vh - 180px);
  overflow-y: scroll;
  // pedestal 70    padding-top 20   padding-bottom 20  navlist  40   footer  30
}
///* 设置垂直滚动条的高度为30像素 */
//::-webkit-scrollbar {
//  height: 30px;
//}
//
///* 设置滚动条的轨道（即滚动条的背景）高度为30像素 */
//::-webkit-scrollbar-track {
//  height: 30px;
//}
//
///* 设置滚动条的滑块（即滚动条的拖动部分）高度为30像素 */
//::-webkit-scrollbar-thumb {
//  height: 30px;
//}

.divider {
  margin: 36px 0;
  height: 1px;
  background: #DCE1F0;
}

.main-row {

}

.title-row {
  .title-text {
    color: #2B3348;
    font-size: 16px;
    font-weight: 600;
  }
}

.input-div {
  margin: 15px 0;
}

.row-container {
  color: #6573A2;
  font-size: 14px;
}
</style>
