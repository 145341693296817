<template>
  <el-popover
      placement="bottom-start"
      popper-class="my-select-pop"
      width="200"
      trigger="hover"
      @show="showFlag=true"
      @hide="showFlag=false"
  >
    <template #reference>
      <div class="placement-text">{{placeholder}}<span v-if="currentProp">&nbsp;({{currentProp}})</span>
        <i class="iconfont unify-zhankaitubiao" :class="showFlag?'top':''" ></i></div>
    </template>
    <div class="options-div">
      <div class="optioned-item" @click="handlerSelect('')">全部</div>
      <div class="optioned-item" @click="handlerSelect(item)" v-for="item in options" :key="item.value">{{item.name}}</div>
    </div>
  </el-popover>
</template>

<script>
import {toRefs,ref} from "vue";

export default {
  props:{
    options : {
      type:Array
    },
    placeholder:{
      type:String
    },
  },
  emits:['handlerSelectEvent'],
  setup(props,{attrs,slots,emit,expose}) {
    let currentProp = ref("");
    let showFlag = ref(false);

    const handlerSelect = function(item){
      if(item){
        currentProp.value = item.name;
        emit('handlerSelectEvent',item.value);
      }else{
        currentProp.value = "";
        emit('handlerSelectEvent','');
      }
    }

    return {
      handlerSelect,
      showFlag,
      currentProp,
      ...toRefs(props)
    }
  }
}
</script>
<style>
.my-select-pop{
  padding: 0 !important;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.placement-text{
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconfont{
  font-size: 12px;
  margin-left: 8px;
  transition: ease 200ms;
  &.top{
    transform: rotate(180deg);
  }
}
.options-div{
  margin: 5px 0;
}
.optioned-item{
  padding: 8px 5px;
  cursor: pointer;
  user-select: none;
}
.optioned-item:hover{
  background: rgb(248, 247, 247);
}

</style>
