<template>
  <div class="baseInfo">
    <div class="main-row flex" v-for="(item,index) in state.params.helpDocumentList">
      <div class="row-tip" style="margin-right: 20px">
        <el-input v-model="state.params.helpDocumentList[index].name" show-word-limit maxlength="100" placeholder="请输入站点名称"/>
      </div>
      <div class="row-content" style="padding-right: 50px">
        <el-input v-model="state.params.helpDocumentList[index].url" show-word-limit maxlength="500" placeholder="请输入站点地址"/>
        <span class="iconfont-container">
        <i class="iconfont one unify-shanchu" @click="deleteOne(index)" v-if="index!=0"></i>
        </span>

      </div>

    </div>
    <div class="add-tip">
      <span @click="addLinkArr">添加一份帮助文档</span>
    </div>
    <el-affix position="bottom" :offset="20">
      <div class="button-container flex a-c j-c">
        <el-button color="#626aef" @click="submit">保存</el-button>
        <el-button color="#626aef" @click="reset" plain>重置</el-button>
      </div>
    </el-affix>
  </div>
</template>

<script>
import {
  findListByUnifyVdatabaseDetailsId2,
  saveHelpDocument,
} from "@/js/network/main/index.js"
import {reactive, ref} from "vue";
import {useRoute} from "vue-router";
import {MessageBox} from "@/js/utils/commonJs";
import {ElMessageBox} from "element-plus";

export default {
  setup() {
    const route = useRoute();
    const state = reactive({
      params: {},

    });
    let paramsCopy = [];
    const addLinkArr = function () {
      state.params.helpDocumentList.push({});
    }
    const deleteOne = function (index) {
      state.params.helpDocumentList.splice(index, 1);
    }
    const init = function () {
      let {id} = route.query
      findListByUnifyVdatabaseDetailsId2({unifyVdatabaseDetailsId: id}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        if (res && res.data && res.data.length>0) {
          state.params.helpDocumentList = res.data;
          paramsCopy = JSON.parse(JSON.stringify(res.data));
        } else {
          state.params.helpDocumentList = [{}];
          paramsCopy = [{}];
        }
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error, "catchError");
      });
    }
    const submit = () => {
      ElMessageBox.confirm('确定要执行本操作吗?').then((aaa) => {
        if (aaa) {
          let {id, dId} = route.query
          saveHelpDocument({helpDocumentList:state.params.helpDocumentList, unifyVdatabaseDetailsId: id}).then(res => {
            if (!res.success) {
              MessageBox.error(res.msg);
              return;
            }
            MessageBox.success("保存成功！");
            window.location.reload();
          }).catch(error => {
            //MessageBox.error(error);
            console.log(error, "catchError");
          });
        }
      }).catch(() => {
      })
    }
    const reset = () => {
      state.params.helpDocumentList = JSON.parse(JSON.stringify(paramsCopy));
    }
    init();
    return {
      state,
      reset,
      submit,
      addLinkArr,
      deleteOne,
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-row {
  margin-bottom: 8px;
}

.button-container {
  padding: 10px 0;
  background-color: white;
}

.row-tip {
  width: 300px;
  font-size: 16px;
  font-weight: 600;
  color: #232323;
}

.row-content {
  width: 600px;
  position: relative;
  box-sizing: border-box;

  .iconfont {
    cursor: pointer;
  }
  .iconfont-container{
    position: absolute;
    right: -10px;
    top: 10px;
  }
  .one{

  }
  .two{
    margin-left: 15px;
  }
}

.add-tip {
  font-size: 14px;
  color: #3157b9;
  cursor: pointer;
  user-select: none;
  margin-bottom: 25px;
}

</style>
