<template>
  <el-dialog
      v-model="state.dialogVisible"
      title="新建标签"
      width="30%"
      :before-close="handleClose"
  >
    <div class="main-container">
      <div class="flex a-c">
        <el-input style="width: 220px" v-model="state.name" show-word-limit maxlength="6" placeholder="输入标签名称，不超过六个字"/>
        <div style="margin-left: 15px" class="color-item" :style="`background:${state.colour}`"></div>
      </div>

      <div class="color-list flex">
        <div class="color-item" v-for="item in colorList" @click="state.colour = item"
             :style="`background:${item}`"></div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="state.dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmHandler">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import {reactive} from 'vue'
import {addLabel} from "@/js/network/main/index"
import {MessageBox} from "@/js/utils/commonJs";

export default {
  props: {
    addTagHandler: {
      type: Function,
    }
  },
  setup(props, {attrs, slots, emit, expose}) {
    const state = reactive({
      dialogVisible: false,
      name: "",
      colour: "",
    })
    const colorList = reactive(["#E75E5B", "#E78F4A", "#5AB7AF", "#52AAEE", "#6E4DBE", "#D6F0F8", "#FFD800", "#6AAA5A"])

    const handleClose = () => {
      state.dialogVisible = false;
      state.name = "";
      state.colour = ""
    }
    const init = () => {
      state.name = "";
      state.dialogVisible = true;
    }
    const confirmHandler = () => {
      if(!state.name){
        MessageBox.warning("标签名必填！");
        return;
      }
      if(!state.colour){
        MessageBox.warning("请选择色块！");
        return;
      }
      addLabel({...state}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        state.dialogVisible = false;
        if(!res || !res.data){
          return;
        }
        props.addTagHandler({...state,id:res.data});
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error,"catchError");
      });

    }
    return {
      state,
      colorList,
      handleClose,
      init,
      confirmHandler,
      ...props
    }

  }
}


</script>
<style scoped>
.dialog-footer button:first-child {
  margin-right: 10px;
}

.color-list {
  margin-top: 20px;
}

.color-item {
  width: 28px;
  height: 28px;
  margin-right: 10px;
  cursor: pointer;
}
</style>
