<template>
  <div class="baseInfo">
    <div class="row-tip" style="margin-bottom: 20px">主站点</div>
    <div class="main-row flex">
      <div class="row-tip" style="margin-right: 20px">
        <el-input v-model="mainUrl.name" show-word-limit maxlength="50" placeholder="请输入站点名称"/>
      </div>
      <div class="row-content" style="padding-right: 50px">
        <el-input v-model="mainUrl.url" show-word-limit maxlength="500" placeholder="请输入站点地址"/>
      </div>

    </div>
    <div class="row-tip" style="margin-bottom: 20px">访问站点</div>
    <div class="main-row flex" v-for="(item,index) in normalUrlList">
      <div class="row-tip" style="margin-right: 20px">
        <el-input v-model="normalUrlList[index].name" show-word-limit maxlength="50" placeholder="请输入站点名称"/>
      </div>
      <div class="row-content" style="padding-right: 50px">
        <el-input v-model="normalUrlList[index].url" show-word-limit maxlength="500" placeholder="请输入站点地址"/>
        <i class="iconfont unify-shanchu" @click="deleteOne(index)" v-if="index!=0"></i>
      </div>

    </div>
    <div class="add-tip">
      <span @click="addLinkArr">添加一个站点</span>
    </div>

    <div class="main-row">
      <div class="row-tip" style="margin-bottom: 20px">CARSI地址</div>
      <div class="row-content" style="width: 920px">
        <el-input v-model="carsiUrl" show-word-limit maxlength="500" placeholder="请输入数据库CARSI地址"/>
      </div>
    </div>
    <el-affix position="bottom" :offset="20">
      <div class="button-container flex a-c j-c">
        <el-button color="#626aef" @click="submit">保存</el-button>
        <el-button color="#626aef" @click="reset" plain>重置</el-button>
      </div>
    </el-affix>
  </div>
</template>

<script>
import {
  findOneUnifyVdatabaseDetailsWebsiteByDetailsId,
  addUnifyVdatabaseDetailsWebsite,
} from "@/js/network/main/index.js"
import {reactive, ref} from "vue";
import {useRoute} from "vue-router";
import {MessageBox} from "@/js/utils/commonJs";
import {ElMessageBox} from "element-plus";

export default {
  setup() {
    const route = useRoute();
    const carsiUrl = ref("");
    const normalUrlList = reactive([{}]);
    const mainUrl = ref({});
    let paramsCopy = {
      normalUrlList:[{}],
      mainUrl:{name:"",url:""},
      carsiUrl:""
    };
    const addLinkArr = function () {
      normalUrlList.push({});
    }
    const deleteOne = function (index) {
      normalUrlList.splice(index, 1);
    }
    const init = function () {
      let {id} = route.query
      findOneUnifyVdatabaseDetailsWebsiteByDetailsId({unifyVdatabaseDetailsId: id}).then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        if (!res ||  !res.data) {
          return
        }
        let normalUrlListArr= res.data.normalUrlList;
        if(normalUrlListArr && normalUrlListArr.length>0){
          normalUrlList.splice(0,1);
          paramsCopy.normalUrlList.splice(0,1);
          normalUrlListArr.forEach(r=>{
            normalUrlList.push(r);
            paramsCopy.normalUrlList.push(r);
          })
        }
        let mainUrlObj = res.data.mainUrl;
        if(mainUrlObj){
          mainUrl.value = mainUrlObj;
          paramsCopy.mainUrl = mainUrlObj
        }
        carsiUrl.value = res.data.carsiUrl;
        if(carsiUrl){
          paramsCopy.carsiUrl = carsiUrl.value;
        }
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error, "catchError");
      });
    }
    const submit = () => {
      ElMessageBox.confirm('确定要执行本操作吗?').then((aaa) => {
        if (aaa) {
          let {id, dId} = route.query
          addUnifyVdatabaseDetailsWebsite({carsiUrl:carsiUrl.value,normalUrlList:normalUrlList,mainUrl:mainUrl.value, unifyVdatabaseDetailsId: id}).then(res => {
            if (!res.success) {
              MessageBox.error(res.msg);
              return;
            }
            MessageBox.success("保存成功！");
            window.location.reload();
          }).catch(error => {
            //MessageBox.error(error);
            console.log(error, "catchError");
          });
        }
      }).catch(() => {
      })
    }
    const reset = () => {
      normalUrlList.splice(0,normalUrlList.length);
      paramsCopy.normalUrlList.forEach((r=> normalUrlList.push(r)));
      mainUrl.value = paramsCopy.mainUrl;
      carsiUrl.value = paramsCopy.carsiUrl;
    }
    init();
    return {
      normalUrlList,
      mainUrl,
      carsiUrl,
      reset,
      submit,
      addLinkArr,
      deleteOne,
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main-row {
  margin-bottom: 8px;
}

.button-container {
  padding: 10px 0;
  background-color: white;
}

.row-tip {
  width: 300px;
  font-size: 16px;
  font-weight: 600;
  color: #232323;
}

.row-content {
  width: 600px;
  position: relative;
  box-sizing: border-box;

  .iconfont {
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
  }
}

.add-tip {
  font-size: 14px;
  color: #3157b9;
  cursor: pointer;
  user-select: none;
  margin-bottom: 25px;
}

</style>
