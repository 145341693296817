<template>
  <div class="navBar">
    <div class="item" :class="act.startsWith(item.path)?'act':''" @click="$router.push(item.path)" v-for="item in routers">
      <i class="iconfont unify-shujukuguanli"/>
      {{item.name}}
    </div>
  </div>
</template>

<script>
import {findMenuListBySchoolId} from "@/js/network/main";
import {MessageBox} from "@/js/utils/commonJs";

export default {
  name: 'NavBar',
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        this.act = newVal.path;
      },
    }
  },
  created() {
    findMenuListBySchoolId().then(res => {

      if (!res.success) {
        MessageBox.error(res.msg);
        this.routersLoading = false;
        return;
      }
      if (!res || !res.data) {
        this.routersLoading = false;
        return;
      }
      this.routers = res.data;
      this.routersLoading = false;
    }).catch(error => {
      this.routersLoading = false;
      console.log(error, "catchError");
    });
  },
  data() {
    return {
      routers:[],
      routersLoading:true,
      act: '/home'
    }
  },

}
</script>

<style scoped lang="scss">
.navBar {
  min-height: 300px;

  .item {
    text-align: left;
    padding: 13px 25px;
    border-radius: 10px;
    margin-bottom: 12px;
    font-size: 15px;
    cursor: pointer;
    transition: 0.2s ease;

    &:hover {
      background-color: #2054d617;
    }

    &.act {
      background-color: #2054D6;
      color: white;
      //font-weight: 600;
    }
  }
}
</style>
