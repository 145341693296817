<template>
  <div class="dbConfig">
    <div>
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane v-for="(item,index) in menus" :key="index" :name="item.name" :label="item.label"/>
      </el-tabs>
    </div>
    <div class="over-main">
      <router-view/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DbConfig',
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        this.act = newVal.path;
        const path = newVal.path;
        this.menus.forEach(item => {
          if (path.endsWith(item.name)) {
            this.activeName = item.name;
          }
        })
      },
    }
  },
  data() {
    return {
      activeName: 'dbNavList',
      menus: [
        {
          name: 'dbNavList',
          label: '导航列表'
        },
        {
          name: 'UserPermissions',
          label: '用户组权限'
        },
        {
          name: 'UserResponsibility',
          label: '责任馆员管理'
        },
        {
          name: 'copyrightNotice',
          label: '版权公告'
        },
        {
          name: 'globalSetting',
          label: '全局设置'
        }
      ]
    }
  },
  created() {

  },
  methods: {
    handleClick(tab, event) {
      this.$router.push("/dbConfig/dbNavManage/" + tab.props.name)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dbConfig {
  //padding: 20px;
}

::v-deep(.el-tabs__header) {
  margin: 0;
}
</style>
