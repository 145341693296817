var download = function (res, name = "",type="word") {
    const disposition = res.headers["content-disposition"];
    let fileName = ""
        fileName = decodeURIComponent(disposition.split("filename*=utf-8''")[1]);


    if (!isBlank(name)) {
        let clearFileName = getFileNameWithOutExt(name);
        fileName = clearFileName + "." + suffix(type);
    } else {
        fileName = fileName.replaceAll("\"", "");
    }

    let blob = new Blob([res.data], {type: applicationEnums[type]});


    if (window.navigator.msSaveOrOpenBlob) {
        // 兼容IE
        navigator.msSaveBlob(blob, fileName);
        return;
    }
    // 创建URL
    let url = URL.createObjectURL(blob);
    // 创建元素 and 下载文件   此方式文件的后缀名由前端控制  文件名 or 后缀名出错会导致文件无法正常预览
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.setAttribute("download", fileName);
    link.click();
    // 释放内存
    URL.revokeObjectURL(url);
    link.remove();
};

var applicationEnums = {
    word:"application/msword",
    excel:"application/vnd.ms-excel"
}

// 获取文件后缀名
const suffix = (fileName) => {
    let suffix = fileName.split(".");
    suffix = suffix[suffix.length - 1];
    return suffix;
};

const getFileNameWithOutExt = (fileName) => {
    let index = fileName.lastIndexOf(".");
    if (index > 0) {
        let ret = fileName.substr(0, index);
        return ret;
    }
    return fileName;
};
//是否是空字符串
const isBlank = (str) => {
    var regu = "^[ ]+$";
    var re = new RegExp(regu);
    return isEmpty(str) || re.test(str);
};

const isEmpty = (str) => {
    return str == undefined || str === "";
};

export {
    download,
}


