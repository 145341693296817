<template>
  <div :id="propId" class="aaa" :style="`height:${echartsHeight}`">

  </div>
</template>

<script>

import {onMounted,ref,markRaw} from "vue";
import * as echarts from "echarts";

export default {
  props: ['propId','echartsHeight'],
  setup(props, {attrs, slots, emit, expose}) {
    const myChart = ref();
    const initEcharts = () => {
      myChart.value = markRaw(echarts.init(document.getElementById(props.propId)));

    };
    const setOption = function (options){
      myChart.value.setOption(options);
      myChart.value.hideLoading();
    }
    const loading = function (){
      myChart.value.showLoading();
    }
    const hideLoading = function(){
      myChart.value.hideLoading();
    }
    onMounted(()=>{
      initEcharts();
    })
    return {
      initEcharts,
      setOption,
      loading,
      hideLoading,
      ...props
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.aaa{
  width: 100%;
}

</style>
