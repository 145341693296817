import {ElMessage} from 'element-plus';
//节流
let timer, flag;
/**
 * 节流原理：在一定时间内，只能触发一次
 *
 * @param {Function} func 要执行的回调函数
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 * @return null
 */
var throttle = function (func, wait = 500, immediate = true) {
    if (immediate) {
        if (!flag) {
            flag = true;
            // 如果是立即执行，则在wait毫秒内开始时执行
            typeof func == 'function' && func();
            timer = setTimeout(() => {
                flag = false;
            }, wait);
        }
    } else {
        if (!flag) {
            flag = true
            // 如果是非立即执行，则在wait毫秒内的结束处执行
            timer = setTimeout(() => {
                flag = false
                typeof func == 'function' && func();
            }, wait);
        }

    }
}
//防抖

let timeout = null;

/**
 * 防抖原理：一定时间内，只有最后一次操作，再过wait毫秒后才执行函数
 *
 * @param {Function} func 要执行的回调函数
 * @param {Number} wait 延时的时间
 * @param {Boolean} immediate 是否立即执行
 * @return null
 */
var debounce = function (func, wait = 500, immediate = false) {
    // 清除定时器
    if (timeout !== null) clearTimeout(timeout);
    // 立即执行，此类情况一般用不到
    if (immediate) {
        var callNow = !timeout;
        timeout = setTimeout(function () {
            timeout = null;
        }, wait);
        if (callNow) typeof func == 'function' && func();
    } else {
        // 设置定时器，当最后一次操作后，timeout不会再被清除，所以在延时wait毫秒后执行func回调方法
        timeout = setTimeout(function () {
            typeof func == 'function' && func();
        }, wait);
    }
}

const MessageBox = {
    success:(msg)=>ElMessage.success(msg),
    warning:(msg)=>ElMessage.warning(msg),
    error:(msg)=> {
        console.log(msg,"msg");
        if(msg.isPrototypeOf(Object)){
            return ElMessage.error("系统错误！");
        }
       return ElMessage.error(msg)
    },
    message:(msg)=>ElMessage(msg)
}
var handleError = function (error) {
    if (error.message == "Request Canceled!") {
        return
    }
    MessageBox.error("系统错误！");
}
export {
    throttle,
    debounce,
    MessageBox,
    handleError
}