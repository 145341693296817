<template>
  <div></div>
</template>
<script>
import {mapState} from 'vuex';

export default {
  name: "302",
  inject: ["initWebSocket"],
  data() {
    return {}
  },
  computed: {
    ...mapState("user", ["userData"]),
  },
  created() {
    try {
      let url = location.href;
      let target = this.$utils.getUrlParam(url, 'target')
      let accessToken = this.$utils.getUrlParam(url, 'accessToken')
      //在本地设置Token
      localStorage.setItem('accessToken', accessToken);
      if (this.$utils.isBlank(target)) {
        let routeUrl = this.$router.resolve({
          path:"/",
        });
        window.open(routeUrl.href,"_self");
      } else {
        let routeUrl = this.$router.resolve({
          path:target,
        });
        window.open(routeUrl.href,"_self");
      }
    } catch (err) {
      console.log(err)
      this.$router.push("/login");
    }
  },
  methods: {}
}
</script>