<template>
  <div class="CopyrightNotice">
    <tinyMceEditor ref="contentRef" :defaultId="'contentRef'" key="contentRef"
                   @update:modelValue="(val)=>updateContent(val)"/>
    <div class="button-container flex a-c j-c" style="margin-top: 20px">
      <el-button color="#626aef" @click="submit">保存</el-button>
      <el-button color="#626aef" plain @click="reset">重置</el-button>
    </div>
  </div>
</template>

<script>
import {
  findOneUnifyNoticeBySid, saveManagerDatabaseDetailsBaseInfo,
  updateUnifyNoticeBySid
} from "@/js/network/main"

import tinyMceEditor from "@/components/untils/tinyMceEditor"
import {onMounted, reactive, ref} from "vue";
import {useRoute} from "vue-router";
import {MessageBox} from "@/js/utils/commonJs";
import {ElMessageBox} from "element-plus";

export default {
  components: {
    tinyMceEditor
  },

  setup(props, {attrs, slots, emit, expose}) {
    const route = useRoute();

    let state = ref("");
    let stateCopy = "";

    let contentRef = ref(null);


    const updateContent = (val) => {
      state.value = val;
    }
    const init = function () {
      // let {id}
      findOneUnifyNoticeBySid().then(res => {
        if (!res.success) {
          MessageBox.error(res.msg);
          return;
        }
        if(!res || !res.data){
          return;
        }
        state.value = res.data.content;
        stateCopy = res.data.content;
        contentRef.value.handleSetContent(state.value);
      }).catch(error => {
        //MessageBox.error(error);
        console.log(error,"catchError");
      });
    }
    const submit = () => {
      ElMessageBox.confirm('确定要执行本操作吗?').then((aaa) => {
        if (aaa) {
          let params ={
            content:state.value
          }
          updateUnifyNoticeBySid(params).then(res => {
            if (!res.success) {
              MessageBox.error(res.msg);
              return;
            }
            MessageBox.success("保存成功！")
          }).catch(error => {
            //MessageBox.error(error);
            console.log(error,"catchError");
          });
        }
      }).catch(() => {
      })

    }
    const reset = () => {
      state.value = stateCopy;
      contentRef.value.init(state.value);
    }
    onMounted(()=>{
      setTimeout(()=>{
        init();
      },400)

    })

    return {
      updateContent,
      contentRef,
      submit,
      reset
    }


  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.CopyrightNotice{
  height: calc(100vh - 180px);
  overflow-y: scroll;
  // pedestal 70    padding-top 20   padding-bottom 20  navlist  40   footer  30
}

</style>
